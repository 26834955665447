import { Outlet, redirect } from 'react-router-dom';
import {
    AlarmReadPermissionSet,
    Restricted,
    alarmDetailsRouterDataId,
} from '../../../features';
import {
    pageLoader as AlarmPageLoader,
    pageClientLoader as AlarmClientLoader,
    pageMapsTabLoader as AlarmMapsLoader,
    pageDevicesTabLoader as AlarmDevicesLoader,
    pageDeviceDetailsTabLoader as AlarmDeviceDetailsLoader,
    pageProceduresTabLoader as AlarmProceduresLoader,
    pageProcedureDetailsTabLoader as AlarmProcedureDetailsLoader,
} from './loader';
import AlarmDetailPage from './alarm-detail-page';
import OverviewTab from './overview-tab';
import ClientTab from './client-tab';
import CaregiversTab from './caregivers-tab';
import NotesTab from './notes-tab';
import DevicesTab from './devices-tab';
import DevicesDetailsTab from './devices-details-tab';
import MedicalTab from './medical-tab';
import HistoryTab from './history-tab';
import ProceduresTab from './procedures-tab';
import ProceduresDetailsTab from './procedures-details-tab';
import MapTab from './map-tab';
import DocumentsTab from './documents-tab';
import ReportsTab from './reports-tab';
import ResidenceClientsTab from './residence-clients-tab';
import { AlarmAccountSetter } from './alarm-account-setter';

const AlarmPageRoutes = {
    path: 'details',
    element: (
        <Restricted toAnyAccount={AlarmReadPermissionSet} redirect={true}>
            <Outlet />
        </Restricted>
    ),
    children: [
        {
            index: true,
            loader: () => redirect('/alarm'),
        },
        {
            id: alarmDetailsRouterDataId,
            path: ':alarmId',
            loader: AlarmPageLoader,
            element: <AlarmAccountSetter />,
            children: [
                {
                    path: 'clients',
                    element: <ResidenceClientsTab />,
                    children: [
                        {
                            id: 'alarm-detail/clients/id',
                            path: ':clientId',
                            element: <AlarmDetailPage />,
                            loader: AlarmClientLoader,
                            children: [
                                {
                                    index: true,
                                    element: <OverviewTab />,
                                    handle: {
                                        //for tabs to find that this route is the index
                                        index: true,
                                    },
                                },
                                {
                                    path: 'client',
                                    element: <ClientTab />,
                                    handle: {
                                        crumb: (t) =>
                                            t(
                                                '62608e08adc29a8d6dbc9754e659f125',
                                                'Client'
                                            ),
                                    },
                                },
                                {
                                    path: 'caregivers',
                                    element: <CaregiversTab />,
                                    handle: {
                                        crumb: (t) =>
                                            t(
                                                'bc77e7fcbeea66aa5fbcd998260f66f5',
                                                'Caregivers'
                                            ),
                                    },
                                },
                                {
                                    path: 'notes',
                                    element: <NotesTab />,
                                    handle: {
                                        crumb: (t) =>
                                            t(
                                                '4358b5009c67d0e31d7fbf1663fcd3bf',
                                                'Notes'
                                            ),
                                    },
                                },
                                {
                                    id: 'alarm-detail/devices',
                                    path: 'devices',
                                    loader: AlarmDevicesLoader,
                                    element: <DevicesTab />,
                                    children: [
                                        {
                                            id: 'alarm-detail/devices/uid',
                                            path: ':deviceUid',
                                            element: <DevicesDetailsTab />,
                                            loader: AlarmDeviceDetailsLoader,
                                            handle: {
                                                crumb: (t) =>
                                                    t(
                                                        'e0212e54ec3a2a120ca0d321b3a60c78',
                                                        'Devices'
                                                    ),
                                            },
                                        },
                                    ],
                                },
                                {
                                    path: 'medical',
                                    element: <MedicalTab />,
                                    handle: {
                                        crumb: (t) =>
                                            t(
                                                '7cbdd4e997c3b8e759f8d579bb30f6f1',
                                                'Medical'
                                            ),
                                    },
                                },
                                {
                                    path: 'history',
                                    element: <HistoryTab />,
                                    handle: {
                                        crumb: (t) =>
                                            t(
                                                '3cd15f8f2940aff879df34df4e5c2cd1',
                                                'History'
                                            ),
                                    },
                                },
                                {
                                    id: 'alarm-detail/procedures',
                                    path: 'procedure',
                                    loader: AlarmProceduresLoader,
                                    element: <ProceduresTab />,
                                    children: [
                                        {
                                            id: 'alarm-detail/procedures/id',
                                            path: ':procedureId',
                                            element: <ProceduresDetailsTab />,
                                            loader: AlarmProcedureDetailsLoader,
                                            handle: {
                                                crumb: (t) =>
                                                    t(
                                                        '662609908ab8e0f372d83dea3511370b',
                                                        'Procedure'
                                                    ),
                                            },
                                        },
                                    ],
                                },
                                {
                                    id: 'alarm-detail/location',
                                    path: 'map',
                                    element: <MapTab />,
                                    loader: AlarmMapsLoader,
                                    handle: {
                                        crumb: (t) =>
                                            t(
                                                '1d78dc8ed51214e518b5114fe24490ae',
                                                'Map'
                                            ),
                                    },
                                },
                                {
                                    path: 'documents',
                                    element: <DocumentsTab />,
                                    handle: {
                                        crumb: (t) =>
                                            t(
                                                '21f64da1e5792c8295b964d159a14491',
                                                'Documents'
                                            ),
                                    },
                                },
                                {
                                    path: 'reports',
                                    element: <ReportsTab />,
                                    handle: {
                                        crumb: (t) =>
                                            t(
                                                'a8445719836f2d5e8b51986410e14728',
                                                'Reports'
                                            ),
                                    },
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ],
};

export default AlarmPageRoutes;
