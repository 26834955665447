import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Tooltip } from '@carbon/react';
import { useTranslation } from 'react-i18next';
import './eds-secret-label.scss';

export function EdsSecretLabel({
    label,
    showSecretTooltipLabel,
    hideSecretTooltipLabel,
    secretCharacter = '•',
    revealForSeconds = 10,
}) {
    const { t } = useTranslation();
    const [revealSecret, setRevealSecret] = useState(false);

    useEffect(() => {
        if (revealSecret) {
            const timer = setTimeout(() => {
                setRevealSecret(false);
            }, revealForSeconds * 1000);

            return () => clearTimeout(timer);
        }
    }, [revealSecret, revealForSeconds]);

    const renderLabel = () => {
        if (revealSecret || _.isEmpty(label)) {
            return label;
        } else {
            return ''.padStart(label.length, secretCharacter);
        }
    };

    return (
        <Tooltip
            label={
                revealSecret
                    ? showSecretTooltipLabel ??
                      t(
                          '6f6ceb56f05f53c132c9a2d775d009d4',
                          'Click to hide secret'
                      )
                    : hideSecretTooltipLabel ??
                      t(
                          '8442d7092e145caa277f6d7b88c17c19',
                          'Click to show secret'
                      )
            }
        >
            <div
                className="eds-secret-label"
                onClick={() => setRevealSecret((currentValue) => !currentValue)}
            >
                {renderLabel()}
            </div>
        </Tooltip>
    );
}
