import {
    createContext,
    memo,
    useCallback,
    useContext,
    useEffect,
    useReducer,
} from 'react';
import authApi from './api';
import { AuthActions, authReducer } from './reducer';
import * as Sentry from '@sentry/react';
import { useNotifications } from '../notifications';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { getLogger } from '../utils';
import _ from 'lodash';
import {
    getLastUsedTenantId,
    setLastUsedAccountId,
    setLastUsedTenantId,
} from '../utils/last-used-tenants';
import { removeReferer, setReferer } from '../utils/referer-redirect';
import i18n from '../i18n';

const logger = getLogger('AuthProvider');

const AuthContext = createContext({
    isLoading: true,
    isAuthenticated: false,
    locale: null,
    attributes: null,
    isTenantSelected: false,
    accountId: null,
    allPermissions: [],
    currentPermissions: [],
    login: () => null,
    logout: () => null,
    oidcCallback: () => null,
    selectTenant: () => null,
    selectAccount: () => null,
    updateAttributes: () => null,
});

export const AuthProvider = memo(({ children }) => {
    const params = useParams();
    const navigate = useNavigate();

    const [state, dispatch] = useReducer(authReducer, {
        isLoading: true,
        attributes: null,
        isAuthenticated: false,
        locale: null,
        accountId: params.accountId,
        allPermissions: [],
        currentPermissions: [],
    });

    const { showError } = useNotifications();
    const { t } = useTranslation();

    useEffect(() => {
        logger.log('AccountId Params changed', params.accountId);
        if (params.accountId) {
            selectAccount(params.accountId);
        }
        // TODO UMO-631 Use useEffectEvent hook and remove linter suppression
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.accountId]);

    useEffect(() => {
        if (state.isAuthenticated) {
            overrideLocale(state.locale);
        }
        // TODO UMO-631 Use useEffectEvent hook and remove linter suppression
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.isAuthenticated, state.locale]);

    const overrideLocale = (locale) => {
        if (_.isNil(locale)) {
            return;
        }

        const checkedOverrideLocale = localStorage.getItem(
            'checkedOverrideLocale'
        );
        if (
            !_.isNil(checkedOverrideLocale) &&
            checkedOverrideLocale === 'true'
        ) {
            return;
        }
        localStorage.setItem('checkedOverrideLocale', true);

        const languages = mapLocaleToLanguages(locale);

        for (const language of languages) {
            if (i18n.options.supportedLngs.includes(language)) {
                if (language !== i18n.language) {
                    logger.log(
                        'Set i18n language to user account locale',
                        language
                    );
                    i18n.changeLanguage(language);
                }
                break;
            }
        }
    };

    const mapLocaleToLanguages = (locale) => {
        const localeConversions = {
            ['no-NO']: 'nb-NO',
        };

        let languages = [localeConversions[locale] ?? locale];
        if (locale.indexOf('-') > 0) {
            languages.push(locale.split('-')[0]);
        }

        return languages;
    };

    const checkAuth = useCallback(async (isAuthenticated) => {
        if (!isAuthenticated) {
            dispatch({ type: AuthActions.Loading, payload: true });
        }
        try {
            let data = await authApi.me();
            if (data.user) {
                Sentry.setUser({
                    email: data?.user?.preferred_username,
                    tenant_id: data?.tenant_id,
                    account_id: data?.account_id,
                });
                dispatch({ type: AuthActions.Login, payload: data });
                return true;
            } else {
                dispatch({ type: AuthActions.Loading, payload: false });
            }
        } catch {
            dispatch({ type: AuthActions.Loading, payload: false });
        }
        return false;
    }, []);

    const login = useCallback(
        async (referer) => {
            if (!_.isNil(referer)) {
                setReferer(referer);
            }

            if (state.isAuthenticated) return;

            try {
                let isLoggedIn = await checkAuth();
                if (isLoggedIn) {
                    navigate('/');
                    return;
                }
                dispatch({ type: AuthActions.Loading, payload: true });
            } catch {
                logger.log('Not authenticated yet');
            }

            authApi.login();
        },
        [state, checkAuth, navigate]
    );

    const logout = useCallback(async () => {
        removeReferer();
        if (!state.isAuthenticated) return;
        try {
            await authApi.logout();
        } catch {
            dispatch({ type: AuthActions.Loading, payload: false });
        }
        dispatch({ type: AuthActions.Logout });
        navigate('/auth/login');
    }, [state, navigate]);

    const oidcCallback = useCallback(
        async (callback_data) => {
            if (state.isLoading) {
                return false;
            }
            dispatch({ type: AuthActions.Loading, payload: true });
            try {
                let data = await authApi.callback(callback_data);
                dispatch({ type: AuthActions.Login, payload: data });
                return true;
            } catch (ex) {
                let title = t(
                    '90b98d01b61e24f409ea6950c96b2e10',
                    'Authorization error'
                );
                let content = t(
                    'f7eba085d0718ba9dbda1f2cfbaa6d5a',
                    'You do not have authorization to access this resource.'
                );
                if (ex?.response?.data?.error === 'no_accounts') {
                    title = t(
                        '7a7c22806d2e093934b32eb2f5830c59',
                        'Invalid tenant'
                    );
                    content = t(
                        '2767167ac2fb836cbd439d42acd12e10',
                        'Could not retrieve accounts of this tenant.'
                    );
                }

                showError({
                    title: title,
                    content: content,
                    ttl: false,
                });
                dispatch({ type: AuthActions.Loading, payload: false });
                return false;
            }
        },
        [state, showError, t]
    );

    const selectTenant = useCallback(
        async (tenantId) => {
            if (!state.isAuthenticated || state.isTenantSelected) return;

            dispatch({ type: AuthActions.Loading, payload: true });
            try {
                await authApi.selectTenant(tenantId);
                dispatch({ type: AuthActions.SelectTenant });
                checkAuth();
                setLastUsedTenantId(tenantId);
            } catch (ex) {
                let content = t(
                    '3c896035c1827afe4f80c1ebf54223d3',
                    'This tenant could not be selected.'
                );
                if (ex?.response?.data?.error === 'no_accounts') {
                    content = t(
                        '2767167ac2fb836cbd439d42acd12e10',
                        'Could not retrieve accounts of this tenant.'
                    );
                }

                showError({
                    title: t(
                        '7a7c22806d2e093934b32eb2f5830c59',
                        'Invalid tenant'
                    ),
                    content: content,
                    ttl: false,
                });
                logout();
            }
        },
        [state, checkAuth, showError, logout, t]
    );

    const selectAccount = useCallback(
        async (accountId) => {
            if (!state.isAuthenticated || !state.isTenantSelected) return;
            dispatch({
                type: AuthActions.SelectAccount,
                payload: accountId,
            });
            setLastUsedAccountId(state.attributes?.tenant_id, accountId);
        },
        [state]
    );

    const updateAttributes = useCallback(async (attributes) => {
        dispatch({
            type: AuthActions.UpdateAttributes,
            payload: attributes,
        });
    }, []);

    const getInitialTenant = () => {
        let tenant = {};

        if (!_.isEmpty(state.attributes?.tenants)) {
            const lastUsedTenantId = getLastUsedTenantId();

            if (!_.isUndefined(lastUsedTenantId)) {
                const lastUsedTenant = state.attributes.tenants.find(
                    (item) => item.umoTenantIdentifier === lastUsedTenantId
                );

                if (!_.isUndefined(lastUsedTenant)) {
                    tenant = lastUsedTenant;
                }
            }
            if (_.isEmpty(tenant)) {
                tenant = _.head(state.attributes?.tenants);
            }
        }

        return tenant;
    };

    return (
        <AuthContext.Provider
            value={{
                ...state,
                login,
                logout,
                oidcCallback,
                checkAuth,
                selectTenant,
                selectAccount,
                updateAttributes,
                getInitialTenant,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
});
AuthProvider.displayName = 'AuthProvider';

export const useAuth = () => useContext(AuthContext);
export const AuthConsumer = AuthContext.Consumer;
