import {
    EdsContainerGrid,
    EdsInlineNotificationsWrapper,
    UmoAlarmsTable,
} from '../../../components';
import { useRouteLoaderData } from 'react-router-dom';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

function OverviewTab() {
    const { t } = useTranslation();
    const { highPriorityNotes, alarm } = useRouteLoaderData('alarm-detail/id');
    const { group } = useRouteLoaderData('alarm-detail/clients/id');

    const formatNotifications = () => {
        let notifications = [];

        if (!_.isUndefined(group) && !_.isEmpty(group.welcomeMessage)) {
            notifications.push({
                id: `group-${group.id}`,
                kind: 'info',
                title: t('504541c02761264f0b5e89dda344acc0', 'Welcome message'),
                hideCloseButton: true,
                content: group.welcomeMessage,
            });
        }

        if (!_.isUndefined(highPriorityNotes)) {
            highPriorityNotes.map((note) => {
                notifications.push({
                    id: `note-${note.id}`,
                    kind: 'warning',
                    title: note.subject,
                    hideCloseButton: true,
                    content: note.content,
                });
            });
        }
        return notifications;
    };

    return (
        <EdsContainerGrid>
            <EdsInlineNotificationsWrapper
                notifications={formatNotifications()}
            />

            <UmoAlarmsTable
                pageSize={5}
                enablePagination={false}
                title={t('c71231a6be114b5166980b8e7484402e', 'Alarm history')}
                baseFilter={`deviceId eq ${alarm.deviceId}`}
                seeMoreButtonLabel={t(
                    'd1c97d695d693b36adc603f41ec93a6d',
                    'See more alarms'
                )}
                seeMoreButtonLink={'./history'}
            />
        </EdsContainerGrid>
    );
}

export default OverviewTab;
