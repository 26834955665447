import { useTranslation } from 'react-i18next';
import {
    EdsContainer,
    EdsContainerGrid,
    EdsDataGrid,
    EdsTableDate,
} from '../../../eds';

export const UmoClientInsuranceInfoGrid = ({ entity, ...props }) => {
    const { t } = useTranslation();

    return (
        <EdsContainerGrid>
            <EdsContainer
                title={t('3de98d6cb488edce3c727e4214bd261e', 'Insurance info')}
                {...props}
            >
                <EdsDataGrid
                    gridData={{
                        styles: {
                            left: {
                                key: {
                                    width: '400px',
                                },
                            },
                            right: {
                                key: {
                                    width: '400px',
                                },
                            },
                        },
                        rows: {
                            left: [
                                {
                                    key: t(
                                        '3020e0124c47341ca13164db169134f3',
                                        'Insurer'
                                    ),
                                    value: entity?.insurer?.name,
                                },
                                {
                                    key: t(
                                        '61c2c0d676c7ba4b13458a93fcd4b4e0',
                                        'Insurance number'
                                    ),
                                    value: entity?.insuranceNumber,
                                },
                                ...(entity?.insuranceStartDate
                                    ? [
                                          {
                                              key: t(
                                                  '79f905dcca60425c92c5f0d4b0c7d800',
                                                  'Insurance start date'
                                              ),
                                              value: (
                                                  <EdsTableDate
                                                      value={
                                                          entity?.insuranceStartDate
                                                      }
                                                  />
                                              ),
                                          },
                                      ]
                                    : []),
                                {
                                    key: t(
                                        'f0cdea2ba4f05bfa47022eed0bfb8051',
                                        'Authorization status'
                                    ),
                                    value: entity?.authorizationStatus?.name,
                                },
                                {
                                    key: t(
                                        '1ef7e22d4e7c7eaf9f8faef7df95b519',
                                        'Authorization number'
                                    ),
                                    value: entity?.authorizationNumber,
                                },
                            ],
                            right: [
                                {},
                                {
                                    key: t(
                                        'e63b7862b731ed6380ad1958796f0220',
                                        'Insurance class'
                                    ),
                                    value: entity?.insuranceClass?.name,
                                },
                                ...(entity?.insuranceEndDate
                                    ? [
                                          {
                                              key: t(
                                                  '7281c87092c2f50882f885e687122388',
                                                  'Insurance end date'
                                              ),
                                              value: (
                                                  <EdsTableDate
                                                      value={
                                                          entity?.insuranceEndDate
                                                      }
                                                  />
                                              ),
                                          },
                                      ]
                                    : entity?.insuranceStartDate
                                    ? [{}]
                                    : []),
                                {},
                                {
                                    key: t(
                                        'df1fda2538f8635a767b2b6f8aa70f9a',
                                        'Authorization date'
                                    ),
                                    value: (
                                        <EdsTableDate
                                            value={entity?.authorizationDate}
                                        />
                                    ),
                                },
                            ],
                        },
                    }}
                />
            </EdsContainer>
        </EdsContainerGrid>
    );
};
