import { useRouteLoaderData } from 'react-router-dom';
import {
    ClientMasterDataReadPermissionSet,
    ClientReadPermissionSet,
    CrmMaintenanceAccountPermissionSet,
    getAccountDocuments,
    getAccountDocumentUrl,
    getClientDocuments,
    getClientDocumentUrl,
    getGroupDocuments,
    getGroupDocumentUrl,
    Restricted,
} from '../../../features';
import { EdsContainerGrid, UmoDocumentsTable } from '../../../components';
import { useTranslation } from 'react-i18next';

function DocumentsTab() {
    const { accountId } = useRouteLoaderData('alarm-detail/id');
    const { client } = useRouteLoaderData('alarm-detail/clients/id');

    const { t } = useTranslation();

    return (
        <EdsContainerGrid>
            <Restricted toAnyAccount={ClientReadPermissionSet}>
                <UmoDocumentsTable
                    title={t(
                        '67ffd82569f95cca9fa4540612b2bf44',
                        'Client documents'
                    )}
                    entity={client}
                    accountId={accountId}
                    getDataCallback={getClientDocuments}
                    getUrlCallback={getClientDocumentUrl}
                    onDeleteClick={undefined}
                    onEditClick={undefined}
                    onHeaderAddClick={undefined}
                />
            </Restricted>
            {client.groupId && (
                <Restricted toAnyAccount={ClientMasterDataReadPermissionSet}>
                    <UmoDocumentsTable
                        title={t(
                            'c5230df311a3218848fa2aceabe52f5c',
                            'Group documents'
                        )}
                        entity={client.group}
                        accountId={accountId}
                        getDataCallback={getGroupDocuments}
                        getUrlCallback={getGroupDocumentUrl}
                        onDeleteClick={undefined}
                        onEditClick={undefined}
                        onHeaderAddClick={undefined}
                    />
                </Restricted>
            )}
            <Restricted toAnyAccount={CrmMaintenanceAccountPermissionSet}>
                <UmoDocumentsTable
                    title={t(
                        'a7ddf6c3745927a1b58e388e2b822e9e',
                        'Account documents'
                    )}
                    accountId={accountId}
                    getDataCallback={getAccountDocuments}
                    getUrlCallback={getAccountDocumentUrl}
                    onDeleteClick={undefined}
                    onEditClick={undefined}
                    onHeaderAddClick={undefined}
                />
            </Restricted>
        </EdsContainerGrid>
    );
}

export default DocumentsTab;
