import { useTranslation } from 'react-i18next';
import {
    EdsContainer,
    EdsContainerGrid,
    EdsDataGrid,
    EdsSecretLabel,
    EdsTableDatetime,
    EdsTableState,
} from '../../../eds';

export const UmoClientContractInfoGrid = ({ entity, ...props }) => {
    const { t } = useTranslation();

    return (
        <EdsContainerGrid>
            <EdsContainer
                title={t('5d6fa5d41d44dedb367cb80dbdda49e1', 'Contract info')}
                {...props}
            >
                <EdsDataGrid
                    gridData={{
                        styles: {
                            left: {
                                key: {
                                    width: '400px',
                                },
                            },
                            right: {
                                key: {
                                    width: '400px',
                                },
                            },
                        },
                        rows: {
                            left: [
                                {
                                    key: t(
                                        '698e3cb990e8de8a48b4c4c961eeea53',
                                        'Client status'
                                    ),
                                    value: entity?.clientStatus?.name,
                                },
                                {
                                    key: t(
                                        '46188e80453520b55cd82a67950f660e',
                                        'Start date'
                                    ),
                                    value: (
                                        <EdsTableDatetime
                                            value={entity?.startDate}
                                        />
                                    ),
                                },
                                ...(entity?.contractEndingReason ? [{}] : []),
                                {
                                    key: t(
                                        '98362d98323b4a89d49b47db4bc03060',
                                        'Loneworker'
                                    ),
                                    value: (
                                        <EdsTableState
                                            validState={true}
                                            invalidState={false}
                                            state={entity.isLoneWorker}
                                        />
                                    ),
                                },
                            ],
                            right: [
                                {
                                    key: t(
                                        '9d0387260c4716615297f599b8adec9a',
                                        'Subscription number'
                                    ),
                                    value: entity?.clientSubscriptionNumber,
                                },
                                ...(entity.endDate
                                    ? [
                                          {
                                              key: t(
                                                  'f49c17d504a2e8f8e29454a6d6ce9ecd',
                                                  'End date'
                                              ),
                                              value: (
                                                  <EdsTableDatetime
                                                      value={entity?.endDate}
                                                  />
                                              ),
                                          },
                                      ]
                                    : [{}]),
                                ...(entity?.contractEndingReason
                                    ? [
                                          {
                                              key: t(
                                                  '9af967f3050f41ff2cffd8536b87f8d2',
                                                  'Contract ending reason'
                                              ),
                                              value: entity
                                                  ?.contractEndingReason?.name,
                                          },
                                      ]
                                    : []),
                                ...(entity?.authenticationCode
                                    ? [
                                          {
                                              key: t(
                                                  'efbcdc1397de705f0e49c0fe58590fe1',
                                                  'Pin code'
                                              ),
                                              value: (
                                                  <EdsSecretLabel
                                                      label={
                                                          entity?.authenticationCode
                                                      }
                                                      showSecretTooltipLabel={t(
                                                          '2757152c09b19b9d2548661aed7b8fc1',
                                                          'Click to hide pin code'
                                                      )}
                                                      hideSecretTooltipLabel={t(
                                                          '93b14f7a9bb031364caef63573240219',
                                                          'Click to show pin code'
                                                      )}
                                                  />
                                              ),
                                          },
                                      ]
                                    : [{}]),
                            ],
                        },
                    }}
                />
            </EdsContainer>
        </EdsContainerGrid>
    );
};
