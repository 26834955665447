export const AlarmsActions = {
    AddEventListener: 'ADD_EVENT_LISTENER',
    RemoveEventListener: 'REMOVE_EVENT_LISTENER',
};

export const alarmsReducer = (state, action) => {
    switch (action.type) {
        case AlarmsActions.AddEventListener: {
            return {
                ...state,
                eventListeners: [...state.eventListeners, action.payload],
            };
        }
        case AlarmsActions.RemoveEventListener: {
            return {
                ...state,
                eventListeners: state.eventListeners.filter(
                    (listener) => listener.id !== action.payload.id
                ),
            };
        }
    }
};
