import _ from 'lodash';
import { scanI18n } from '.';
import * as Carbon from '@carbon/icons-react';

export const isValidHexColor = (color) => {
    const regex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
    return regex.test(color);
};

export const getCarbonIcon = (iconName, fallback = 'Unknown') => {
    return Carbon[iconName] ?? Carbon[fallback];
};

export const alarmCriterionIcon = Object.freeze({
    Alarm: {
        icon: 'Notification',
        label: scanI18n('6486b4e98228b83d4b13d54febe5f170', 'Alarm'),
    },
    Chat: {
        icon: 'Chat',
        label: scanI18n('aa8af3ebe14831a7cd1b6d1383a03755', 'Chat'),
    },
    Clipboard: {
        icon: 'Result',
        label: scanI18n('bbc4df4047bb91ef515ce03a6317fd65', 'Clipboard'),
    },
    Fire: {
        icon: 'Fire',
        label: scanI18n('015f28b9df1bdd36427dd976fb73b29d', 'Fire'),
    },
    Silent: {
        icon: 'NotificationOff',
        label: scanI18n('73be252ca82217b1458a25e6b4e99f15', 'Silent'),
    },
    System: {
        icon: 'WarningHex',
        label: scanI18n('54b53072540eeeb8f8e9343e71f28176', 'System'),
    },
    Technical: {
        icon: 'Devices',
        label: scanI18n('2840180493ff3073dc8ed330c972437d', 'Technical'),
    },
});

export const alarmCriterionColor = Object.freeze({
    Blue: {
        color: '#3AB9F0',
        label: scanI18n('48d6215903dff56238e52e8891380c8f', 'Blue'),
    },
    BlueDark: {
        color: '#11538E',
        label: scanI18n('5a28a51d0227df32237a9918d6e43f46', 'Dark blue'),
    },
    Black: {
        color: '#171414',
        label: scanI18n('1ffd9e753c8054cc61456ac7fac1ac89', 'Black'),
    },
    Green: {
        color: '#0E6027',
        label: scanI18n('9f27410725ab8cc8854a2769c7a516b8', 'Green'),
    },
    Red: {
        color: '#E54963',
        label: scanI18n('bda9643ac6601722a28f238714274da4', 'Red'),
    },
    Yellow: {
        color: '#F1C21B',
        label: scanI18n('d487dd0b55dfcacdd920ccbdaeafa351', 'Yellow'),
    },
    Orange: {
        color: '#FF832B',
        label: scanI18n('fe01d67a002dfa0f3ac084298142eccd', 'Orange'),
    },
});

export const getDefaultsForCriterionIcon = (criterion) => {
    let defaultValues = {
        icon: alarmCriterionIcon.Alarm.icon,
        color: alarmCriterionColor.Blue.color,
    };

    if (_.isNil(criterion)) {
        return defaultValues;
    }

    if (criterion === 207 || criterion === 128) {
        // Fire alarm
        defaultValues.icon = alarmCriterionIcon.Fire.icon;
        defaultValues.color = alarmCriterionColor.Red.color;
    } else if (criterion >= 100 && criterion < 200) {
        // Alarm
        return defaultValues;
    } else if (criterion > 200 && criterion < 899) {
        // Technical alarm
        defaultValues.icon = alarmCriterionIcon.Technical.icon;
        defaultValues.color = alarmCriterionColor.Green.color;
    } else if (criterion > 899 && criterion <= 999) {
        // System alarm
        defaultValues.icon = alarmCriterionIcon.System.icon;
        defaultValues.color = alarmCriterionColor.Yellow.color;
    }

    return defaultValues;
};
