import { useTranslation } from 'react-i18next';
import './umo-image-view.scss';

export const UmoImageView = (props) => {
    const { t } = useTranslation();

    if (!props?.src) {
        return t('168f6271829b5ac458c6855b5c77faec', 'Loading failed');
    }

    let src = props.src;
    if (props.noCaching) {
        src += '&t=' + new Date().getTime();
    }

    return <img className="umo-image-view" src={src} />;
};
