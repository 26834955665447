export * from './form-validation';
export * from './logger';
import _ from 'lodash';
import { DateTime } from 'luxon';

/*eslint no-undef: "off"*/
export const isDevMode =
    !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export const defaultPageSize = 10;
export const availablePageSizes = [5, 10, 20, 30, 40, 50];

export const accountIdHeaderName = 'x-umo-account-id';
export const alarmDetailsRouterDataId = 'alarm-detail/id';

export const consumerStates = Object.freeze({
    ContractRequest: 'ContractRequest',
    Started: 'Started',
    Ended: 'Ended',
});

export const genders = Object.freeze({
    Unknown: 'Unknown',
    Male: 'Male',
    Female: 'Female',
});

export const getGenderLabel = (t, value) => {
    if (value === 'male') {
        return t('07cf4f8f5d8b76282917320715dda2ad', 'Male');
    } else if (value === 'female') {
        return t('273b9ae535de53399c86a9b83148a8ed', 'Female');
    } else {
        return t('5da248ea6840aca1ae2b417b17982a89', 'Neutral');
    }
};

export const getAlarmStatusLabel = (t, value) => {
    if (value === 'received') {
        return t('c5946eb9400717fd6f40e26e36cdb498', 'Received');
    } else if (value === 'active') {
        return t('c76a5e84e4bdee527e274ea30c680d79', 'Active');
    } else if (value === 'followup') {
        return t('ff853246d679191f35c7c966204d0ec9', 'Follow-up');
    } else if (value === 'closed') {
        return t('349e686330723975502e9ef4f939a5ac', 'Closed');
    } else {
        return '-';
    }
};

export const getFileSizeLabel = (bytes, iec = false) => {
    if (iec) {
        if (bytes >= 1073741824) {
            return `${(bytes / 1073741824).toFixed(1)} GiB`;
        } else if (bytes >= 1048576) {
            return `${(bytes / 1048576).toFixed(1)} MiB`;
        } else if (bytes >= 1024) {
            return `${(bytes / 1024).toFixed(1)} kiB`;
        }
    }
    if (bytes >= 1000000000) {
        return `${(bytes / 1000000000).toFixed(1)} GB`;
    } else if (bytes >= 1000000) {
        return `${(bytes / 1000000).toFixed(1)} MB`;
    } else if (bytes >= 1000) {
        return `${(bytes / 1000).toFixed(1)} kB`;
    }

    return `${bytes} b`;
};

export const addLeadingZeros = (value, length) => {
    return _.toString(value).padStart(length, '0');
};

export const decomposePrefixedNumber = (value) => {
    let prefix;
    let number;

    if (!_.isUndefined(value)) {
        const prefixNumberRegex = /^(.*?)(\d*)$/g;
        const matches = [...value.matchAll(prefixNumberRegex)];

        if (matches.length === 1) {
            prefix = matches[0][1];
            number = matches[0][2];
        }
    }

    return {
        prefix: prefix,
        number: !_.isEmpty(number) ? number : undefined,
        numberOfDigits: !_.isEmpty(number) ? number.length : 0,
    };
};

export const getDeviceRange = (deviceCode, numberOfDevices) => {
    if (
        !_.isString(deviceCode) ||
        !_.isNumber(numberOfDevices) ||
        _.isNaN(numberOfDevices)
    ) {
        return null;
    }

    const { prefix, number, numberOfDigits } =
        decomposePrefixedNumber(deviceCode);
    const numberAsNumber = Number(number);

    if (!_.isFinite(numberAsNumber)) {
        return null;
    }

    const start = numberAsNumber;
    const end = start + (numberOfDevices - 1);
    const first = prefix + addLeadingZeros(start, numberOfDigits);
    const last = prefix + addLeadingZeros(end, numberOfDigits);

    return {
        prefix,
        numberOfDigits,
        rangeSize: numberOfDevices,
        start,
        end,
        first,
        last,
    };
};

export const getRange = (fromCode, toCode, numberOfDigits) => {
    const from = decomposePrefixedNumber(fromCode);
    const fromNumber = Number(from.number);

    const to = decomposePrefixedNumber(toCode);
    const toNumber = Number(to.number);

    if (
        !_.isFinite(fromNumber) ||
        !_.isFinite(toNumber) ||
        fromNumber >= toNumber
    ) {
        return null;
    }

    return {
        prefix: from.prefix,
        numberOfDigits: numberOfDigits,
        rangeSize: toNumber - fromNumber + 1,
        start: from.number,
        end: to.number,
        first: from.prefix + addLeadingZeros(fromNumber, numberOfDigits),
        last: from.prefix + addLeadingZeros(toNumber, numberOfDigits),
    };
};

// Creates a debounced function that can invoke a function leading subsequent
// calls, or trailing subsequent calls.
//
// Setting option.trailing = false (default) creates a debounced function that
// invokes the func immediately and ignores subsequent calls for options.wait
// milliseconds.
//
// Setting option.trailing = true creates a debounced function that delays
// invoking func until after options.wait milliseconds have elapsed since the
// last time the debounce function was invoked.
//
// The items in the options.params array are added as parameters when invoking
// func.
//
// The parameter timeoutIdRef is a useRef React Hook storing the previous ID
// generarated by the setTimeout() call.
export const debounce = (timeoutIdRef, func, options) => {
    // default options values
    let trailing = false;
    let delay = 500;
    let params = [];

    if (options != null && _.isPlainObject(options)) {
        trailing = options?.trailing || trailing;
        delay = options?.delay || delay;
        params = options?.params || params;
    }

    if (trailing) {
        debounceTrailing(timeoutIdRef, func, delay, params);
    } else {
        debounceLeading(timeoutIdRef, func, delay, params);
    }
};

// Creates a debounced function that delays invoking func until after wait
// milliseconds have elapsed since the last time the debounce function was
// invoked.
const debounceTrailing = (timeoutIdRef, func, delay, params) => {
    clearTimeout(timeoutIdRef.current);

    timeoutIdRef.current = setTimeout(
        async () => {
            func(...params);
        },
        delay,
        func,
        params
    );
};

// Creates a debounced function that invokes the func immediately and ignores
// subsequent calls for timeout milliseconds.
const debounceLeading = (timeoutIdRef, func, timeout, params) => {
    if (_.isNull(timeoutIdRef.current)) {
        func(...params);
    }

    clearTimeout(timeoutIdRef.current);
    timeoutIdRef.current = setTimeout(
        () => {
            timeoutIdRef.current = null;
        },
        timeout,
        timeoutIdRef
    );
};

const setEmptyFieldToValue = (obj, value, removeKeys = []) => {
    return Object.keys(obj).reduce((acc, key) => {
        if (!removeKeys.includes(key)) {
            acc[key] = obj[key] === '' ? value : obj[key];
            if (_.isPlainObject(obj[key])) {
                acc[key] = setEmptyFieldToValue(obj[key], value, removeKeys);
            }
        }
        return acc;
    }, {});
};

export const setEmptyFieldToNull = (obj, removeKeys = []) => {
    return setEmptyFieldToValue(obj, null, removeKeys);
};

export const setEmptyFieldToUndefined = (obj) => {
    return setEmptyFieldToValue(obj, undefined);
};

const flattenObjectAndRemoveEmptyValues = (obj) => {
    return _.compact(
        _.flatMapDeep(obj, (value) => {
            if (_.isPlainObject(value)) {
                return flattenObjectAndRemoveEmptyValues(value);
            }
            return !_.isNil(value) ? value : [];
        })
    ).sort();
};

export const areObjectValuesEqual = (object1, object2) => {
    const flatObject1 = flattenObjectAndRemoveEmptyValues(object1);
    const flatObject2 = flattenObjectAndRemoveEmptyValues(object2);
    return _.isEqual(flatObject1, flatObject2);
};

export const getDate = (options = {}) => {
    const {
        plusDays = 0,
        minusDays = 0,
        plusYears = 0,
        minusYears = 0,
        plusHours = 0,
        minusHours = 0,
        plusMinutes = 0,
        minusMinutes = 0,
        resetTime = true,
    } = options;

    let now = DateTime.now();

    if (resetTime) {
        now = now.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    }

    return now
        .plus({
            minutes: plusMinutes,
            hours: plusHours,
            days: plusDays,
            years: plusYears,
        })
        .minus({
            minutes: minusMinutes,
            hours: minusHours,
            days: minusDays,
            years: minusYears,
        })

        .toJSDate();
};

export const toJSDate = (date, allowEmpty = false) => {
    let dateTimeObject;
    if (date instanceof Date) {
        dateTimeObject = DateTime.fromJSDate(date);
    } else if (!_.isObject(date)) {
        dateTimeObject = DateTime.fromISO(date);
    }
    if (dateTimeObject && dateTimeObject.isValid) {
        return dateTimeObject.toJSDate();
    }
    return allowEmpty ? '' : getDate();
};

export const isValidDate = (date) => {
    return _.isDate(toJSDate(date, true));
};

export const getTimeStringFromJSDate = (JSDate) => {
    if (!isValidDate(JSDate)) {
        return {};
    }

    const { minutes, hours } = getTimeFromJSDate(JSDate);

    return `${hours}:${minutes}`;
};

export const getTimeFromJSDate = (JSDate) => {
    if (!isValidDate(JSDate)) {
        return {};
    }
    JSDate = toJSDate(JSDate);

    const minutes = (JSDate.getMinutes() < 10 ? '0' : '') + JSDate.getMinutes();
    const hours = (JSDate.getHours() < 10 ? '0' : '') + JSDate.getHours();
    return {
        minutes: minutes,
        hours: hours,
    };
};

export const defaultDropdownMappingCallback = (data) => {
    if (_.isUndefined(data)) {
        return {};
    }
    return { id: data.id, text: data.name };
};

export const estimatedRemainingUploadTime = (startTime, uploaded, fileSize) => {
    const currentTime = new Date().valueOf();
    const uploadTime = currentTime - startTime;

    let leftToUpload = fileSize - uploaded;
    if (leftToUpload < 0) {
        leftToUpload = 0;
    }

    let remainingTimeMs = (uploadTime / uploaded) * leftToUpload;
    return formatDuration(remainingTimeMs);
};

export const formatDuration = (ms) => {
    if (ms < 0) {
        ms = 0;
    }
    const time = {
        day: Math.floor(ms / 86400000),
        hour: Math.floor(ms / 3600000) % 24,
        minute: Math.floor(ms / 60000) % 60,
        second: Math.floor(ms / 1000) % 60,
    };
    return Object.entries(time)
        .filter((val) => val[1] !== 0)
        .map(
            (val) =>
                val[1] + ' {{' + (val[1] !== 1 ? val[0] + 's' : val[0]) + '}}'
        )
        .join(', ');
};

export function flattenObjectWithDivider(
    ob,
    prefix = false,
    result = null,
    divider = '/'
) {
    result = result || {};

    // Preserve empty objects and arrays, they are lost otherwise
    if (prefix && _.isObject(ob) && !_.isNull(ob) && _.isEmpty(ob)) {
        result[prefix] = _.isArray(ob) ? [] : {};
        return result;
    }

    prefix = prefix ? prefix + divider : '';

    for (const i in ob) {
        if (Object.prototype.hasOwnProperty.call(ob, i)) {
            // Only recurse on true objects and arrays, ignore custom classes like dates
            if (_.isObject(ob[i]) && !_.isNull(ob[i])) {
                // Recursion on deeper objects
                flattenObjectWithDivider(ob[i], prefix + i, result);
            } else {
                result[prefix + i] = ob[i];
            }
        }
    }
    return result;
}

export const getLink = (to, accountId) => {
    let pathname = to?.pathname ? to.pathname : to;

    if (
        _.startsWith(pathname, '/') &&
        !_.startsWith(pathname, '/auth') &&
        !_.startsWith(pathname, '/alarm') &&
        !_.startsWith(pathname, '/account') &&
        !_.isUndefined(accountId)
    ) {
        pathname = `/account/${accountId}${pathname}`;
        if (to?.pathname) {
            return {
                ...to,
                pathname,
            };
        } else {
            return pathname;
        }
    }
    return to;
};

export const scanI18n = (...args) => {
    return args;
};

export const DaysOfTheWeek = Object.freeze({
    Sunday: scanI18n('787c74a2e618a696e34e025adda33ad3', 'Sunday'),
    Monday: scanI18n('944ba223a5c1b5f4b495708e7cd5ee37', 'Monday'),
    Tuesday: scanI18n('1a31a6f65cc993ff6bd9a5b85f0520b0', 'Tuesday'),
    Wednesday: scanI18n('aae446086f8e81194ed115b566bf2e3d', 'Wednesday'),
    Thursday: scanI18n('c395246f710b0e2c86b7ed82f7f56ce3', 'Thursday'),
    Friday: scanI18n('f6f7fec07f372b7bd5eb196bbca0f3f4', 'Friday'),
    Saturday: scanI18n('6036c3d913f49f28f35d734589840310', 'Saturday'),
});

export const ConsumerStates = Object.freeze({
    Started: scanI18n('3bebb2f89180b03fa5ae736665f648d9', 'Started'),
    ContractRequest: scanI18n(
        '35a7af0f7a34d71cb4917d0f0ad80bc2',
        'Contract request'
    ),
    Ended: scanI18n('dd2677d07f29951449ecbd2ab3b896eb', 'Ended'),
});

export const isValidUrl = (url) => {
    try {
        const urlObject = new URL(url);
        return (
            urlObject.protocol === 'http:' || urlObject.protocol === 'https:'
        );
    } catch {
        return false;
    }
};

export const isExternalURL = (url) => {
    if (!isValidUrl(url)) {
        return false;
    }
    return new URL(url).host !== window.location.host;
};

export const searchInObjectByKeyValue = (array, key, value) => {
    return _.filter(array, [key, value])[0];
};
