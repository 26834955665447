import { forwardRef, useState } from 'react';
import {
    EdsCheckbox,
    EdsDatePicker,
    EdsDatePickerType,
    EdsForm,
    EdsFormColumn,
    EdsFormGroup,
    EdsNumberInput,
    EdsTextArea,
    EdsTextInput,
} from '../../../eds';
import { useTranslation } from 'react-i18next';
import { getDate, putNote, useNotifications } from '../../../../features';
import { CheckboxGroup, Row } from '@carbon/react';
import _ from 'lodash';

const UmoNotesForm = forwardRef(
    (
        {
            postDataCallback,
            hideDefaultSubmit = false,
            showHighPriority = false,
            initValues,
        },
        ref
    ) => {
        const { showSuccess, showError } = useNotifications();
        const { t } = useTranslation();
        const [highPriority, setHighPriority] = useState(
            initValues?.isHighPriority?.value ?? false
        );
        const [hiddenForAlarming, setHiddenForAlarming] = useState(
            initValues?.isHiddenForAlarming?.value ?? false
        );

        const formDefinition = {
            sortIndex: {
                value: '',
                validation: {
                    type: 'number',
                    required: false,
                },
            },
            subject: {
                validation: {
                    required: true,
                },
            },
            validFrom: {
                value: getDate(),
                validation: {
                    type: 'datepicker',
                    required: false,
                },
            },
            validTo: {
                validation: {
                    type: 'datepicker',
                    required: false,
                },
            },
            content: {
                validation: {
                    required: true,
                },
            },
            ...(showHighPriority && {
                isHighPriority: {
                    value: false,
                    validation: {
                        type: 'boolean',
                        required: false,
                    },
                },
            }),
            isHiddenForAlarming: {
                value: false,
                validation: {
                    type: 'boolean',
                    required: false,
                },
            },
            isReadonlyForAlarming: {
                value: false,
                validation: {
                    type: 'boolean',
                    required: false,
                },
            },
        };

        const saveData = async (form) => {
            if (_.isEmpty(form.sortIndex)) {
                //sortIndex can be empty but when empty it must be set to 0 for UDS
                form.sortIndex = 0;
            }

            form.content = form.content.trim();

            if (!form.id) {
                return await postDataCallback(form);
            } else {
                return await putNote(form);
            }
        };

        const onSubmit = async (_event, form, isValid) => {
            if (!isValid) {
                throw false;
            }

            try {
                const response = await saveData(form);

                showSuccess({
                    title: t('536a056b710e94b16169efd17a4a657b', 'Saved'),
                    content: t(
                        '281aef713c8dc9007cabb1e65448917d',
                        "{{entity}} '{{name}}' is saved",
                        {
                            entity: t(
                                'aad653ca3ee669635f2938b73098b6d7',
                                'Note'
                            ),
                            name: form.subject,
                        }
                    ),
                });

                return response;
            } catch (error) {
                showError({
                    title: t('cb5e100e5a9a3e7f6d1fd97512215282', 'Error'),
                    content: t(
                        'df0a9d46baf7315909e4389a04786e3d',
                        'Oops something went wrong'
                    ),
                });

                throw error;
            }
        };

        return (
            <EdsForm
                hideDefaultSubmit={hideDefaultSubmit}
                initValues={initValues}
                formDefinition={formDefinition}
                onSubmit={onSubmit}
                ref={ref}
            >
                <EdsFormGroup>
                    <Row>
                        <EdsFormColumn>
                            <EdsNumberInput
                                name="sortIndex"
                                label={t(
                                    '70a17ffa722a3985b86d30b034ad06d7',
                                    'Order'
                                )}
                            ></EdsNumberInput>
                        </EdsFormColumn>
                    </Row>
                    <Row>
                        <EdsFormColumn>
                            <EdsTextInput
                                name="subject"
                                label={t(
                                    'b5e3374e43f6544852f7751dfc529100',
                                    'Subject'
                                )}
                            ></EdsTextInput>
                        </EdsFormColumn>
                    </Row>
                    <Row>
                        <EdsFormColumn fullWidth>
                            <EdsDatePicker
                                type={EdsDatePickerType.DateRange}
                                fromName="validFrom"
                                fromLabel={t(
                                    '167b2d7828511b4bdbb6d642029bdaff',
                                    'Valid from'
                                )}
                                toName="validTo"
                                toLabel={t(
                                    '6e1f5c87ac9e0d969abb679a0603b54d',
                                    'Valid to'
                                )}
                            />
                        </EdsFormColumn>
                    </Row>
                    <Row>
                        <EdsFormColumn fullWidth>
                            <CheckboxGroup legendText="">
                                {showHighPriority && (
                                    <EdsCheckbox
                                        name="isHighPriority"
                                        labelText={t(
                                            'bc77d45d120bf8057eb10fa8602e186b',
                                            'High priority'
                                        )}
                                        disabled={hiddenForAlarming}
                                        onChangeCallback={(event) => {
                                            setHighPriority(
                                                event?.target?.checked
                                            );
                                        }}
                                    />
                                )}
                                <EdsCheckbox
                                    name="isHiddenForAlarming"
                                    labelText={t(
                                        '3ed59be82c072bab87032eb2801d245e',
                                        'Hidden for alarming'
                                    )}
                                    disabled={highPriority}
                                    onChangeCallback={(event) => {
                                        setHiddenForAlarming(
                                            event?.target?.checked
                                        );
                                    }}
                                />
                                <EdsCheckbox
                                    name="isReadonlyForAlarming"
                                    labelText={t(
                                        'e663c3f44d3ed67332c9eab112a7199e',
                                        'Read only for alarming'
                                    )}
                                />
                            </CheckboxGroup>
                        </EdsFormColumn>
                    </Row>
                    <Row>
                        <EdsFormColumn fullWidth>
                            <EdsTextArea
                                name="content"
                                label={t(
                                    'aad653ca3ee669635f2938b73098b6d7',
                                    'Note'
                                )}
                                rows={10}
                            ></EdsTextArea>
                        </EdsFormColumn>
                    </Row>
                </EdsFormGroup>
            </EdsForm>
        );
    }
);
UmoNotesForm.displayName = 'NotesForm';

export default UmoNotesForm;
