import { useTranslation } from 'react-i18next';
import {
    EdsDataGridHeader,
    EdsTableDatetimeSeconds,
    EdsTabs,
} from '../../../components';
import './alarm-detail-page.scss';
import { useRouteLoaderData } from 'react-router-dom';
import { getAlarmStatusLabel, getFullGroup } from '../../../features';
import {
    FlowLogsVpc,
    Information,
    Notebook,
    UserMultiple,
    UserProfile,
    Document,
    Time,
    HealthCross,
    Devices,
    Wikis,
    ChartRelationship,
} from '@carbon/icons-react';

function AlarmDetailPage() {
    const { alarm, residence } = useRouteLoaderData('alarm-detail/id');
    const { client } = useRouteLoaderData('alarm-detail/clients/id');
    const { t } = useTranslation();

    const tabs = [
        {
            label: t('bce059749d61c1c247c303d0118d0d53', 'Overview'),
            icon: Information,
            transparent: true,
            index: true,
        },
        {
            label: t('62608e08adc29a8d6dbc9754e659f125', 'Client'),
            icon: UserProfile,
            transparent: true,
            slug: 'client',
        },
        {
            label: t('bc77e7fcbeea66aa5fbcd998260f66f5', 'Caregivers'),
            icon: UserMultiple,
            transparent: true,
            slug: 'caregivers',
        },
        {
            label: t('4358b5009c67d0e31d7fbf1663fcd3bf', 'Notes'),
            icon: Notebook,
            transparent: true,
            slug: 'notes',
        },
        {
            label: t('e0212e54ec3a2a120ca0d321b3a60c78', 'Devices'),
            icon: Devices,
            transparent: true,
            slug: 'devices',
            hasChildren: true,
        },
        {
            label: t('7cbdd4e997c3b8e759f8d579bb30f6f1', 'Medical'),
            icon: HealthCross,
            transparent: true,
            slug: 'medical',
        },
        {
            label: t('3cd15f8f2940aff879df34df4e5c2cd1', 'History'),
            icon: Time,
            transparent: true,
            slug: 'history',
        },
        {
            label: t('662609908ab8e0f372d83dea3511370b', 'Procedure'),
            icon: ChartRelationship,
            transparent: true,
            slug: 'procedure',
            hasChildren: true,
        },
        {
            label: t('1d78dc8ed51214e518b5114fe24490ae', 'Map'),
            icon: Wikis,
            transparent: true,
            slug: 'map',
        },
        {
            label: t('21f64da1e5792c8295b964d159a14491', 'Documents'),
            icon: Document,
            transparent: true,
            slug: 'documents',
        },
        {
            label: t('a8445719836f2d5e8b51986410e14728', 'Reports'),
            icon: FlowLogsVpc,
            transparent: true,
            slug: 'reports',
        },
    ];

    return (
        <>
            <EdsDataGridHeader
                gridData={{
                    styles: {
                        width: '1000px',
                        left: {
                            key: {
                                width: '400px',
                            },
                        },
                    },
                    rows: {
                        left: [
                            {
                                key: t(
                                    '9acb44549b41563697bb490144ec6258',
                                    'Status'
                                ),
                                value: getAlarmStatusLabel(
                                    t,
                                    alarm.status?.toLowerCase()
                                ),
                            },
                            {
                                key: t(
                                    '7d637d275668ed6d41a9b97e6ad3a556',
                                    'Age'
                                ),
                                value: client.age,
                            },
                            {
                                key: t(
                                    '8512ae7d57b1396273f76fe6ed341a23',
                                    'Language'
                                ),
                                value: client.person?.language?.name,
                            },
                            {
                                key: t(
                                    'db0f6f37ebeb6ea09489124345af2a45',
                                    'Group'
                                ),
                                value: getFullGroup(client),
                            },
                        ],
                        right: [
                            {
                                key: t(
                                    '15c46c6e8cc3bc71a1f7ac25fd043a38',
                                    'Criteria'
                                ),
                                value: `${alarm.criterion} - ${alarm.alarmCriterion?.name}`,
                            },
                            {
                                key: t(
                                    '722f67226873aa1653ef19c6cc71e64e',
                                    'Time received'
                                ),
                                value: (
                                    <EdsTableDatetimeSeconds
                                        value={alarm.timeStamp}
                                    />
                                ),
                            },
                            {
                                key: t(
                                    'd5189de027922f81005951e6efe0efd5',
                                    'Location'
                                ),
                                value: alarm.alarmLocation?.description,
                            },
                            {
                                key: t(
                                    '884d9804999fc47a3c2694e49ad2536a',
                                    'Address'
                                ),
                                value: residence.combinedAddressField,
                            },
                        ],
                    },
                }}
            />
            <EdsTabs tabs={tabs} router={true}></EdsTabs>
        </>
    );
}

export default AlarmDetailPage;
