import { useTranslation } from 'react-i18next';
import {
    EdsContainerTable,
    EdsOptionalLink,
    EdsTableDatetimeSeconds,
} from '../../../eds';
import _ from 'lodash';

export const UmoAlarmActionsTable = ({
    getDataCallback,
    clientId,
    ...props
}) => {
    const { t } = useTranslation();

    return (
        <EdsContainerTable
            title={
                props.title ??
                t('97fb1cc7cb71599fae62f2ce48ca7120', 'Alarm actions')
            }
            headers={[
                {
                    key: 'timestamp',
                    header: t('07cc694b9b3fc636710fa08b6922c42b', 'Time'),
                },
                {
                    key: 'action',
                    header: t('418c5509e2171d55b0aee5c2ea4442b5', 'Action'),
                },
                {
                    key: 'operator',
                    header: t('4b583376b2767b923c3e1da60d10de59', 'Operator'),
                },
                {
                    key: 'caregiver',
                    header: t('ce0cf55cf4a60dd070516a07331009f0', 'Caregiver'),
                },
                {
                    key: 'remark',
                    header: t('911c185c8f3c475cdca2ef9cf12166da', 'Remark'),
                },
            ]}
            getDataCallback={getDataCallback}
            mappingCallback={(responseData) => {
                return {
                    id: responseData.id,
                    timestamp: (
                        <EdsTableDatetimeSeconds
                            value={responseData.timestamp}
                        />
                    ),
                    action: responseData.action?.description,
                    operator: responseData.operatorName,
                    caregiver: !_.isNil(responseData.personalCaregiverId) ? (
                        !_.isNil(clientId) ? (
                            <EdsOptionalLink
                                id={responseData.personalCaregiverId}
                                to={`/crm/client/${clientId}/caregivers/${responseData.personalCaregiverId}`}
                                label={responseData.combinedName}
                            />
                        ) : (
                            responseData.combinedName
                        )
                    ) : (
                        <EdsOptionalLink
                            id={responseData.professionalCaregiverId}
                            to={`/crm/professional-caregiver/${responseData.professionalCaregiverId}`}
                            label={responseData.combinedName}
                        />
                    ),
                    remark: responseData?.remark,
                };
            }}
        ></EdsContainerTable>
    );
};
