import {
    createSearchFilterStoreFunctions,
    createStateManagementFunctions,
    deleteRequest,
    getRequest,
    patchRequest,
    postRequest,
    putRequest,
} from '../api';

const basePath = '/schemes';

export const getSchemeSearch = async (search) => {
    return getRequest(`${basePath}/search`, search);
};

export const saveScheme = async (scheme) => {
    return postRequest(`${basePath}`, scheme);
};

export const patchScheme = async (id, scheme) => {
    return patchRequest(`${basePath}/${id}`, scheme);
};

export const postSchemeValidate = async (data) => {
    return postRequest(`${basePath}/validate`, data);
};

export const postSchemeValidateById = async (schemeId, data) => {
    return postRequest(`${basePath}/validate/${schemeId}`, data);
};

export const getSchemes = async () => {
    return getRequest(basePath);
};

export const getScheme = async (id) => {
    return getRequest(`${basePath}/${id}`);
};

export const getSchemeByUID = async (uid) => {
    return getRequest(`${basePath}/uid/${uid}`);
};

export const getSchemePhoneNumbers = async (id) => {
    return getRequest(`${basePath}/${id}/phone-numbers`);
};

export const postSchemePhoneNumber = async (id, number) => {
    return postRequest(`${basePath}/${id}/phone-numbers`, number);
};

export const getSchemeResidences = async (id) => {
    return getRequest(`${basePath}/${id}/residences`);
};

export const getSchemeCareProviders = async (id) => {
    return getRequest(`${basePath}/${id}/care-providers`);
};

export const getSchemeDevices = async (id) => {
    return getRequest(`${basePath}/${id}/devices`);
};

export const getSchemeNotes = async (id, params) => {
    return getRequest(`${basePath}/${id}/notes`, params);
};

export const postSchemeNote = async (id, note) => {
    return postRequest(`${basePath}/${id}/notes`, note);
};

export const getSchemeTags = async (id) => {
    return getRequest(`${basePath}/${id}/tags`);
};

export const postSchemeTag = async (id, tag) => {
    return postRequest(`${basePath}/${id}/tags`, tag);
};

export const stateManagement = {
    ...createStateManagementFunctions(basePath, 'schemes'),
};

export const searchFilterStore = {
    ...createSearchFilterStoreFunctions(basePath, 'schemes'),
};

export const getSchemeLinkToProfessionalCaregiver = async (
    schemeId,
    professionalCaregiverId
) => {
    return getRequest(
        `${basePath}/${schemeId}/professional-caregivers/${professionalCaregiverId}/link`
    );
};

export const postLinkSchemeToProfessionalCaregiver = async (
    schemeId,
    professionalCaregiverId,
    data
) => {
    return postRequest(
        `${basePath}/${schemeId}/professional-caregivers/${professionalCaregiverId}/link`,
        data
    );
};

export const putSchemeLinkToProfessionalCaregiver = async (
    schemeId,
    professionalCaregiverId,
    data
) => {
    return putRequest(
        `${basePath}/${schemeId}/professional-caregivers/${professionalCaregiverId}/link`,
        data
    );
};

export const getSchemeLinkToCareteam = async (schemeId, careTeamId) => {
    return getRequest(`${basePath}/${schemeId}/care-teams/${careTeamId}/link`);
};

export const postLinkSchemeToCareTeam = async (schemeId, careTeamId, data) => {
    return postRequest(
        `${basePath}/${schemeId}/care-teams/${careTeamId}/link`,
        data
    );
};

export const putSchemeLinkToCareTeam = async (schemeId, careTeamId, data) => {
    return putRequest(
        `${basePath}/${schemeId}/care-teams/${careTeamId}/link`,
        data
    );
};

export const deleteUnlinkSchemeToProfessionalCaregiver = async (
    schemeId,
    professionalCaregiverId
) => {
    return deleteRequest(
        `${basePath}/${schemeId}/professional-caregivers/${professionalCaregiverId}/unlink`
    );
};

export const deleteUnlinkSchemeToCareTeam = async (schemeId, careTeamId) => {
    return deleteRequest(
        `${basePath}/${schemeId}/care-teams/${careTeamId}/unlink`
    );
};
