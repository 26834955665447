import { useRouteLoaderData } from 'react-router-dom';
import { EdsPage, EdsTabs } from '../../../components';
import _ from 'lodash';

export default function ResidenceClientsTab() {
    const { alarmClient, clients } = useRouteLoaderData('alarm-detail/id');

    const tabs = [
        {
            label: alarmClient?.person?.combinedName,
            slug: `${alarmClient?.id}`,
            hasChildren: true,
        },
    ];

    if (!_.isEmpty(clients)) {
        clients
            .filter((item) => item.id !== alarmClient.id)
            .map((item) => {
                tabs.push({
                    label: item?.person?.combinedName,
                    slug: `${item?.id}`,
                    hasChildren: true,
                });
            });
    }

    return (
        <div className="alarm-detail-page">
            <EdsPage>
                <EdsTabs tabs={tabs} router={true} headerTabs={true} />
            </EdsPage>
        </div>
    );
}
