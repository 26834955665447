import { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EdsActionButtonTypes, EdsContainerTable } from '../../..';
import _ from 'lodash';
import {
    getMedicalData,
    setEmptyFieldToNull,
    useModal,
    useNotifications,
    useNavigate,
} from '../../../../features';
import { UmoLinkMedicalData } from './link-medical-data';

export const UmoLinkedMedicalDataTable = forwardRef(
    ({ getDataCallback, postDataCallback, onUnlinkClick, ...props }, ref) => {
        const navigate = useNavigate();
        const { t } = useTranslation();
        const { showSuccess, showError } = useNotifications();
        const { showLinkModal } = useModal();
        const [title] = useState(
            props.title ??
                t('abfba08b69bc186be75fe422f18d1f84', 'Linked medical data')
        );

        const [hideWhenNoData] = useState(props.hideWhenNoData ?? true);
        const [hasData, setHasData] = useState();
        const [medicalDataIds, setMedicalDataIds] = useState([]);

        const getData = async () => {
            let data = await getDataCallback();
            let newMedicalDataIds = [];
            for (let index in data) {
                let item = data[index];
                if (_.isNumber(item.medicalData?.id)) {
                    newMedicalDataIds.push(item.medicalData.id);
                }
            }
            setMedicalDataIds(newMedicalDataIds);
            setHasData(!_.isEmpty(data));
            return data;
        };

        const linkNewMedicalData = async (form) => {
            const postData = setEmptyFieldToNull(form);

            try {
                const response = await postDataCallback({
                    medicalDataId: postData.selected,
                    medicalPriorityId: postData.medicalPriorityId,
                });

                showSuccess({
                    title: t('536a056b710e94b16169efd17a4a657b', 'Saved'),
                    content: t(
                        '5be667d4a923c2575e183041beafed1e',
                        '{{entity}} is linked',
                        {
                            entity: t(
                                '9e4e7d840e193b8ede42a28210bf137b',
                                'Medical data'
                            ),
                        }
                    ),
                });

                ref.current?.fetchInitData();

                return response;
            } catch (error) {
                showError({
                    title: t('cb5e100e5a9a3e7f6d1fd97512215282', 'Error'),
                    content: t(
                        'df0a9d46baf7315909e4389a04786e3d',
                        'Oops something went wrong'
                    ),
                });

                throw error;
            }
        };

        if (
            hideWhenNoData &&
            !_.isUndefined(hasData) &&
            _.isBoolean(hasData) &&
            !hasData
        ) {
            return null;
        }

        return (
            <EdsContainerTable
                hidden={_.isUndefined(hasData)}
                title={title}
                ref={ref}
                headers={[
                    {
                        key: 'category',
                        header: t(
                            'c4ef352f74e502ef5e7bc98e6f4e493d',
                            'Category'
                        ),
                        sort: 'category',
                    },
                    {
                        key: 'medicalData',
                        header: t(
                            '9e4e7d840e193b8ede42a28210bf137b',
                            'Medical data'
                        ),
                        sort: 'medicalData',
                    },
                    {
                        key: 'priority',
                        header: t(
                            'b988295c268025b49dfb3df26171ddc3',
                            'Priority'
                        ),
                        sort: 'priority',
                    },
                ]}
                getDataCallback={getData}
                onHeaderLinkClick={
                    !_.isFunction(postDataCallback)
                        ? undefined
                        : () => {
                              showLinkModal({
                                  title: t(
                                      '3becff32c38aa9cb1c4e8cf6aef424b8',
                                      'Link medical data'
                                  ),
                                  tableRef: ref,
                                  children: (
                                      <UmoLinkMedicalData
                                          {...props}
                                          getDataCallback={getData}
                                          additionalLinkDataFilterCallback={(
                                              medicalData
                                          ) => {
                                              return !medicalDataIds.includes(
                                                  medicalData.id
                                              );
                                          }}
                                          onSubmit={async (form) => {
                                              if (
                                                  !_.isFunction(
                                                      postDataCallback
                                                  )
                                              ) {
                                                  return false;
                                              }
                                              return linkNewMedicalData(form);
                                          }}
                                      />
                                  ),
                              });
                          }
                }
                onUnlinkClick={
                    !_.isFunction(onUnlinkClick)
                        ? undefined
                        : (row) => {
                              let data = ref.current?.getRowData(row.id);
                              return onUnlinkClick({ row: row, data: data });
                          }
                }
                mappingCallback={(responseData) => {
                    return {
                        id: responseData.id,
                        category: responseData?.medicalDataCategory?.name,
                        medicalDataId: responseData?.medicalData?.id,
                        medicalData: responseData?.medicalData?.name,
                        priority: responseData?.medicalPriority?.name,
                        ...((_.isNil(responseData?.medicalData?.infoUrl) ||
                            _.isEmpty(responseData?.medicalData?.infoUrl)) && {
                            hideActionButtons: [EdsActionButtonTypes.OpenLink],
                        }),
                    };
                }}
                openLinkLabel={t(
                    'dd530e802ac59054d102f0eca9c048fb',
                    'Open info URL'
                )}
                onOpenLinkClick={async (row) => {
                    const data = ref.current?.getRowData(row?.id);
                    if (data?.medicalDataId) {
                        const medicalData = await getMedicalData(
                            data.medicalDataId
                        );
                        navigate(medicalData?.infoUrl);
                    }
                }}
            />
        );
    }
);
UmoLinkedMedicalDataTable.displayName = 'UmoLinkedMedicalDataTable';
