import { redirect } from 'react-router-dom';
import { validate as uuidValidate } from 'uuid';
import {
    getAlarm,
    getAlarmProcedure,
    getAlarmProceduresByCriteria,
    getClient,
    getClientByUID,
    getAlarmDevicePositions,
    getClientDevices,
    getClientNotes,
    getDeviceByUID,
    getResidence,
    getResidenceByUID,
    getSchemeNotes,
    getTenantGoogleMapsSettings,
    getAlarmState,
    getResidenceClients,
    accountIdHeaderName,
} from '../../../features';
import axios from '../../../features/utils/axios';
import { redirectPageLoaderError } from '../../../features/utils/loader';
import _ from 'lodash';

//TODO needs proper checks to ensure nothing breaks if the alarm data is not complete
const setAccountId = async (alarmId) => {
    const alarmState = await getAlarmState(alarmId);
    axios.defaults.headers.common[accountIdHeaderName] = alarmState.account_id;

    return alarmState.account_id;
};

const getHighPriorityNotes = async (clientId, schemeId) => {
    let clientNotes, schemeNotes;

    const params = {
        isHighPriority: true,
        isValid: true,
    };

    let requests = [];
    requests.push(getClientNotes(clientId, params));
    requests.push(getSchemeNotes(schemeId, params));

    const response = await Promise.all(requests);
    clientNotes = response[0] ?? [];
    schemeNotes = response[1] ?? [];

    return [...clientNotes, ...schemeNotes];
};

export const pageLoader = async ({ params }) => {
    try {
        const accountId = await setAccountId(params.alarmId);
        const alarm = await getAlarm(params.alarmId);

        let alarmClient = {},
            residence = {},
            highPriorityNotes = [],
            clients = [];

        if (!_.isNil(alarm.clientId) || !_.isNil(alarm.clientUID)) {
            alarmClient = !_.isNil(alarm.clientUID)
                ? await getClientByUID(alarm.clientUID)
                : await getClient(alarm.clientId);

            if (_.isUndefined(params.clientId)) {
                return redirect(`clients/${alarmClient.id}`);
            }
        }

        if (!_.isNil(alarm.residenceId) || !_.isNil(alarm.residenceUID)) {
            residence = !_.isNil(alarm.residenceUID)
                ? await getResidenceByUID(alarm.residenceUID)
                : await getResidence(alarm.residenceId);

            clients = await getResidenceClients(residence.id);
        }

        if (
            !_.isNil(alarm.clientId) &&
            !_.isEmpty(residence) &&
            !_.isNil(residence.schemeId)
        ) {
            highPriorityNotes = await getHighPriorityNotes(
                alarm.clientId,
                residence.schemeId
            );
        }

        return {
            accountId,
            alarm,
            alarmClient,
            residence,
            highPriorityNotes,
            clients,
        };
    } catch (ex) {
        return redirectPageLoaderError(ex);
    }
};

export const pageClientLoader = async ({ params }) => {
    try {
        await setAccountId(params.alarmId);
        let client = {};
        if (uuidValidate(params.clientId)) {
            client = await getClientByUID(params.clientId);
        } else {
            client = await getClient(params.clientId);
        }
        return {
            client,
            group: client.group,
        };
    } catch (ex) {
        return redirectPageLoaderError(ex);
    }
};

export const pageDevicesTabLoader = async ({ params }) => {
    try {
        await setAccountId(params.alarmId);
        let devices = await getClientDevices(params.clientId);

        if (params.deviceUid) {
            return devices;
        }

        if (_.isEmpty(devices)) {
            return null;
        }

        const firstDevice = _.first(devices);
        return redirect(firstDevice.deviceUID);
    } catch {
        return {};
    }
};

export const pageDeviceDetailsTabLoader = async ({ params }) => {
    try {
        await setAccountId(params.alarmId);
        const accountId = await setAccountId(params.alarmId);
        const device = await getDeviceByUID(params.deviceUid);
        return { accountId, device };
    } catch (ex) {
        return redirectPageLoaderError(ex);
    }
};

export const pageProceduresTabLoader = async ({ params }) => {
    try {
        await setAccountId(params.alarmId);
        const alarm = await getAlarm(params.alarmId);
        let procedures = [];
        if (!_.isNil(alarm.clientId) || !_.isNil(alarm.clientUID)) {
            const client = !_.isNil(alarm.clientUID)
                ? await getClientByUID(alarm.clientUID)
                : await getClient(alarm.clientId);

            procedures = await getAlarmProceduresByCriteria(alarm.criterion, {
                groupId: client.groupId ?? null,
            });
        }

        if (params.procedureId) {
            return procedures;
        }

        if (_.isEmpty(procedures)) {
            return null;
        }

        const firstProcedure = _.first(procedures);
        return redirect(firstProcedure.id);
    } catch {
        return {};
    }
};

export const pageProcedureDetailsTabLoader = async ({ params }) => {
    try {
        const accountId = await setAccountId(params.alarmId);
        const procedure = await getAlarmProcedure(params.procedureId);
        return { accountId, procedure };
    } catch (ex) {
        return redirectPageLoaderError(ex);
    }
};

export const pageMapsTabLoader = async ({ params }) => {
    try {
        await setAccountId(params.alarmId);
        let requests = [];
        requests.push(getTenantGoogleMapsSettings());
        requests.push(getAlarmDevicePositions(params.alarmId));

        const response = await Promise.all(requests);
        return {
            googleMapsSettings: response[0] ?? {},
            devicePositions: response[1] ?? [],
        };
    } catch {
        return {};
    }
};
