import {
    EdsContainerGrid,
    UmoClientMedicalInfoGrid,
    UmoLinkedMedicalDataTable,
    UmoLinkedMedicinesDataTable,
} from '../../../components';
import {
    ClientExtensionReadPermissionSet,
    getClientAllMedicalData,
    getClientMedicines,
    Restricted,
} from '../../../features';
import { useRouteLoaderData } from 'react-router-dom';

function MedicalTab() {
    const { client } = useRouteLoaderData('alarm-detail/clients/id');

    return (
        <EdsContainerGrid>
            <UmoClientMedicalInfoGrid entity={client} />
            <Restricted to={ClientExtensionReadPermissionSet}>
                <UmoLinkedMedicalDataTable
                    hideWhenNoData={false}
                    getDataCallback={() => getClientAllMedicalData(client.id)}
                />

                <UmoLinkedMedicinesDataTable
                    hideWhenNoData={false}
                    getDataCallback={() => getClientMedicines(client.id)}
                />
            </Restricted>
        </EdsContainerGrid>
    );
}

export default MedicalTab;
