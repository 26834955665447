import { useRouteLoaderData } from 'react-router-dom';
import {
    EdsContainerGrid,
    UmoAlarmActionsTable,
    UmoAlarmDetailGrid,
    UmoAlarmReasonsTable,
    UmoRenderedHelpTable,
} from '../../../components';
import {
    getAlarmActions,
    getAlarmReasons,
    getAlarmRenderedHelp,
} from '../../../features';

function ReportsTab() {
    const { alarm } = useRouteLoaderData('alarm-detail/id');
    const { client } = useRouteLoaderData('alarm-detail/clients/id');

    return (
        <EdsContainerGrid>
            <UmoAlarmDetailGrid entity={alarm} />

            <UmoAlarmActionsTable
                getDataCallback={() => getAlarmActions(alarm.id)}
                clientId={client.id}
            />

            <UmoAlarmReasonsTable
                getDataCallback={() => getAlarmReasons(alarm.id)}
            />

            <UmoRenderedHelpTable
                getDataCallback={() => getAlarmRenderedHelp(alarm.id)}
            />
        </EdsContainerGrid>
    );
}

export default ReportsTab;
