import { useTranslation } from 'react-i18next';
import {
    EdsContainerTable,
    EdsOptionalLink,
    EdsTableDatetimeSeconds,
} from '../../../eds';
import { useRef, useState } from 'react';
import _ from 'lodash';
import {
    getAlarmSearch,
    getAlarmStatusLabel,
    useModal,
    useNavigate,
} from '../../../../features';

export const UmoAlarmsTable = ({
    visibleHeaderKeys = [
        'timeStamp',
        'deviceCode',
        'criteria',
        'type',
        'cli',
        'status',
    ],
    baseFilter,
    ...props
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const tableRef = useRef(null);

    const [title] = useState(
        props.title ?? t('0500cd47ceefb6776f9d279889775775', 'Alarms')
    );

    const headers = [
        {
            key: 'timeStamp',
            header: t('01c76cca45a8dc7a3cd3097e734ee65b', 'Received on'),
        },
        {
            key: 'deviceCode',
            header: t('57d50ee4873d42049cc021a5df38b750', 'Device code'),
        },
        {
            key: 'deviceCodeNoLink',
            header: t('57d50ee4873d42049cc021a5df38b750', 'Device code'),
        },
        {
            key: 'criteria',
            header: t('15c46c6e8cc3bc71a1f7ac25fd043a38', 'Criteria'),
        },
        {
            key: 'client',
            header: t('62608e08adc29a8d6dbc9754e659f125', 'Client'),
        },
        {
            key: 'residence',
            header: t('cad4811efcdb1000aae7a6841d995713', 'Residence'),
        },
        {
            key: 'type',
            header: t('906eb66e47713fb446120ba0c8622552', 'Alarm type'),
        },
        {
            key: 'cli',
            header: t('0898b22730d57afcd394d8e4889ece4a', 'CLI'),
        },
        {
            key: 'status',
            header: t('9acb44549b41563697bb490144ec6258', 'Status'),
        },

        {
            key: 'remark',
            header: t('911c185c8f3c475cdca2ef9cf12166da', 'Remark'),
        },
    ];

    const getVisibleHeaders = () => {
        if (!visibleHeaderKeys) {
            return headers;
        }

        const test = visibleHeaderKeys.map((key) =>
            headers.find((h) => h.key === key)
        );

        return test;
    };

    const isVisible = (headerKey) => {
        if (!visibleHeaderKeys) {
            return true;
        }
        return visibleHeaderKeys.includes(headerKey);
    };

    const mapCriteria = (responseData) => {
        if (responseData?.alarmCriterion) {
            return `${responseData?.criterion} - ${responseData?.alarmCriterion?.name} (${responseData?.alarmLocation?.description})`;
        } else {
            return `${responseData?.criterion} - ${responseData?.criterionName} (${responseData?.locationDescription})`;
        }
    };

    const addBaseFilter = (filter) => {
        const filterParts = [];

        if (!_.isUndefined(filter)) {
            filterParts.push(filter);
        }

        if (baseFilter) {
            filterParts.push(baseFilter);
        }

        if (_.isEmpty(filterParts)) {
            return undefined;
        }

        return filterParts.join(' and ');
    };

    const getDataCallbackMethod = async ({ $filter, ...params }) => {
        return getAlarmSearch({
            ...params,
            $filter: addBaseFilter($filter),
        });
    };

    return (
        <EdsContainerTable
            title={title}
            ref={tableRef}
            headers={getVisibleHeaders()}
            searchClientSide={false}
            fetchServerSideDataOnInit={true}
            getDataCallback={getDataCallbackMethod}
            mappingCallback={(responseData) => {
                return {
                    id: responseData.id,
                    ...(isVisible('timeStamp') && {
                        timeStamp: (
                            <EdsTableDatetimeSeconds
                                value={responseData.timeStamp}
                            />
                        ),
                    }),
                    ...(isVisible('deviceCode') && {
                        deviceCode: !_.isNil(responseData.deviceUID) ? (
                            <EdsOptionalLink
                                id={responseData?.deviceUID}
                                to={`/crm/device/${responseData?.deviceUID}`}
                                label={responseData.deviceCode}
                            />
                        ) : (
                            <EdsOptionalLink
                                id={responseData?.deviceId ?? responseData}
                                to={`/crm/device/${responseData?.deviceId}`}
                                label={responseData.deviceCode}
                            />
                        ),
                    }),
                    ...(isVisible('deviceCodeNoLink') && {
                        deviceCodeNoLink: responseData.deviceCode,
                    }),
                    ...(isVisible('criteria') && {
                        criteria: mapCriteria(responseData),
                    }),
                    ...(isVisible('client') && {
                        client: !_.isNull(responseData.clientUID) ? (
                            <EdsOptionalLink
                                id={responseData?.clientUID}
                                to={`/crm/client/${responseData?.clientUID}`}
                                label={t(
                                    '62608e08adc29a8d6dbc9754e659f125',
                                    'Client'
                                )}
                            />
                        ) : (
                            <EdsOptionalLink
                                id={responseData?.clientId}
                                to={`/crm/client/${responseData?.clientId}`}
                                label={t(
                                    '62608e08adc29a8d6dbc9754e659f125',
                                    'Client'
                                )}
                            />
                        ),
                    }),
                    ...(isVisible('residence') && {
                        residence: !_.isNull(responseData.residenceId) ? (
                            <EdsOptionalLink
                                id={responseData?.residenceId}
                                to={`/crm/residence/${responseData?.residenceId}`}
                                label={t(
                                    'cad4811efcdb1000aae7a6841d995713',
                                    'Residence'
                                )}
                            />
                        ) : (
                            <EdsOptionalLink
                                id={responseData?.residenceUID}
                                to={`/crm/residence/${responseData?.residenceUID}`}
                                label={t(
                                    'cad4811efcdb1000aae7a6841d995713',
                                    'Residence'
                                )}
                            />
                        ),
                    }),
                    ...(isVisible('type') && {
                        type:
                            responseData?.alarmType?.name ??
                            responseData?.alarmTypeName,
                    }),
                    ...(isVisible('cli') && {
                        cli: responseData?.cli,
                    }),
                    ...(isVisible('status') && {
                        status: getAlarmStatusLabel(
                            t,
                            responseData.status.toLowerCase()
                        ),
                    }),
                    ...(isVisible('remark') && {
                        remark: responseData?.operatorRemarks,
                    }),
                };
            }}
            // todo add onclick to show modalbox with alarm details in UMO-304
            //for now go directly to the alarm detail view
            onRowClick={(row) => {
                navigate(`/crm/alarm/${row.id}`);
            }}
            {...props}
        />
    );
};

export const UmoAlarmsTableForClientAndScheme = ({ ...props }) => {
    return (
        <UmoAlarmsTable
            {...props}
            visibleHeaderKeys={[
                'timeStamp',
                'deviceCode',
                'criteria',
                'client',
                'residence',
                'type',
                'cli',
                'status',
            ]}
        />
    );
};

export const UmoAlarmsTableForParentAlarm = ({ ...props }) => {
    return (
        <UmoAlarmsTable
            {...props}
            visibleHeaderKeys={[
                'timeStamp',
                'deviceCode',
                'criteria',
                'client',
                'residence',
                'type',
                'cli',
                'remark',
                'status',
            ]}
        />
    );
};

export const UmoAlarmsTableForDeviceErrors = ({ modalId, ...props }) => {
    const navigate = useNavigate();
    const { remove } = useModal();

    return (
        <UmoAlarmsTable
            {...props}
            visibleHeaderKeys={[
                'timeStamp',
                'deviceCodeNoLink',
                'criteria',
                'type',
                'cli',
                'status',
            ]}
            onRowClick={(row) => {
                navigate(`/crm/alarm/${row.id}`);
                if (!_.isUndefined(modalId)) {
                    remove(modalId);
                }
            }}
        />
    );
};
