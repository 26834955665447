import { useTranslation } from 'react-i18next';
import {
    EdsContainer,
    EdsContainerGrid,
    EdsDataGrid,
    EdsTableDate,
} from '../../../eds';
import { getGenderLabel } from '../../../../features';

export const UmoClientPersonalInfoGrid = ({ entity, ...props }) => {
    const { t } = useTranslation();

    return (
        <EdsContainerGrid>
            <EdsContainer
                title={
                    props.title ??
                    t('6cf31af044e0080f7681302ba1db53f3', 'Personal info')
                }
                {...props}
            >
                <EdsDataGrid
                    gridData={{
                        styles: {
                            left: {
                                key: {
                                    width: '400px',
                                },
                            },
                            right: {
                                key: {
                                    width: '400px',
                                },
                            },
                        },
                        rows: {
                            left: [
                                {
                                    key: t(
                                        'b068931cc450442b63f5b3d276ea4297',
                                        'Name'
                                    ),
                                    value: entity?.person?.combinedName,
                                },
                                {
                                    key: t(
                                        '1b64f409e392564f28b5b62e894293ab',
                                        'Birthdate'
                                    ),
                                    value: (
                                        <EdsTableDate
                                            value={entity?.person?.birthDate}
                                        />
                                    ),
                                },
                                {
                                    key: t(
                                        '8512ae7d57b1396273f76fe6ed341a23',
                                        'Language'
                                    ),
                                    value: entity?.person?.language?.name,
                                },
                                {
                                    key: t(
                                        'cc90f1913b83d255b95be0e0fea6d576',
                                        'Gender'
                                    ),
                                    value: getGenderLabel(
                                        t,
                                        entity?.person?.gender.toLowerCase()
                                    ),
                                },
                                {
                                    key: t(
                                        '533c6e90083c9312631bd50350d09c2f',
                                        'Social security number'
                                    ),
                                    value: entity?.socialSecurityNumber,
                                },
                                {
                                    key: t(
                                        '0c83f57c786a0b4a39efab23731c7ebc',
                                        'Email'
                                    ),
                                    value: entity?.defaultEmailAddress,
                                },
                            ],
                            right: [
                                {},
                                {
                                    key: t(
                                        '7d637d275668ed6d41a9b97e6ad3a556',
                                        'Age'
                                    ),
                                    value: entity?.age,
                                },
                                {
                                    key: t(
                                        '093c346f612429cdfa02b440d89e7a9b',
                                        'Marital state'
                                    ),
                                    value: entity?.maritalState?.name,
                                },
                                {},
                                {},
                                {
                                    key: t(
                                        '911c185c8f3c475cdca2ef9cf12166da',
                                        'Remark'
                                    ),
                                    value: entity?.remarks,
                                },
                            ],
                        },
                    }}
                />
            </EdsContainer>
        </EdsContainerGrid>
    );
};
