import { useRouteLoaderData } from 'react-router-dom';
import {
    ClientExtensionReadPermissionSet,
    getClientExternalIdentifiers,
    getClientNotes,
    getClientTags,
    getResidenceNotes,
    NotesReadPermissionSet,
    postClientNote,
    postResidenceNote,
    Restricted,
} from '../../../features';
import {
    EdsContainerGrid,
    UmoLinkedExternalIdentifiersTable,
    UmoNotesTable,
    UmoNotesTableForClient,
    UmoTagsTable,
} from '../../../components';
import { useTranslation } from 'react-i18next';

function NotesTab() {
    const { client } = useRouteLoaderData('alarm-detail/clients/id');
    const { t } = useTranslation();

    return (
        <EdsContainerGrid>
            <Restricted toAnyAccount={NotesReadPermissionSet}>
                <UmoNotesTableForClient
                    title={t(
                        '00bede61ee4a5bb12d07e0aa267fbc3a',
                        'Client notes'
                    )}
                    inAlarming={true}
                    getDataCallback={(params) =>
                        getClientNotes(client.id, params)
                    }
                    postDataCallback={(note) => postClientNote(client.id, note)}
                />

                {client.residenceId && (
                    <UmoNotesTable
                        title={t(
                            'f7019da1012e8e3eb686bb0044d6b7ea',
                            'Residence notes'
                        )}
                        inAlarming={true}
                        getDataCallback={(params) =>
                            getResidenceNotes(client.residenceId, params)
                        }
                        postDataCallback={(note) =>
                            postResidenceNote(client.residenceId, note)
                        }
                    />
                )}
            </Restricted>

            <Restricted toAnyAccount={ClientExtensionReadPermissionSet}>
                <UmoTagsTable
                    getDataCallback={(params) =>
                        getClientTags(client.id, params)
                    }
                    tagCategoryParams={{
                        IsValidForClients: true,
                        IsActive: true,
                    }}
                    tagParams={{
                        IsActive: true,
                    }}
                    onDeleteClick={undefined}
                    onEditClick={undefined}
                    onHeaderAddClick={undefined}
                />
                <UmoLinkedExternalIdentifiersTable
                    hideWhenNoData={false}
                    getDataCallback={() =>
                        getClientExternalIdentifiers(client.id)
                    }
                    onDeleteClick={undefined}
                    onEditClick={undefined}
                    onHeaderAddClick={undefined}
                />
            </Restricted>
        </EdsContainerGrid>
    );
}

export default NotesTab;
