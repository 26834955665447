import { useTranslation } from 'react-i18next';
import {
    EdsContainer,
    EdsContainerGrid,
    EdsDataGrid,
    EdsLink,
    EdsTableDatetimeSeconds,
    EdsTableState,
} from '../../../eds';
import _ from 'lodash';

export const UmoDeviceAliveGrid = ({ entity, aliveStateLink }) => {
    const { t } = useTranslation();

    const getAliveStateLabel = (aliveState) => {
        let label;
        let state;
        if (aliveState === 'NoAliveReceived') {
            label = t('71d941c06bf060b5daebf8cd321422b8', 'No alive received');
            state = false;
        } else if (aliveState === 'AliveReceived') {
            label = t('bee02d0a63e1579a9cb97b506d668376', 'Alive received');
            state = true;
        } else if (aliveState === 'NoMonitoring') {
            return t('20824b689a875de180b13becc2ea2a2d', 'No monitoring');
        } else {
            return aliveState;
        }
        return (
            <EdsTableState
                label={
                    !_.isEmpty(aliveStateLink) ? (
                        <EdsLink label={label} to={aliveStateLink} />
                    ) : (
                        label
                    )
                }
                validState={true}
                invalidState={false}
                state={state}
            />
        );
    };

    return (
        <EdsContainerGrid>
            <EdsContainer
                title={t(
                    'd4e30b8eff3082c6db12b2584bbc370c',
                    'Alive information'
                )}
            >
                <EdsDataGrid
                    gridData={{
                        styles: {
                            left: {
                                key: {
                                    width: '400px',
                                },
                            },
                            right: {
                                key: {
                                    width: '400px',
                                },
                            },
                        },
                        rows: {
                            left: [
                                {
                                    key: t(
                                        '0a757311696e8dbb1028ef66f1612a5d',
                                        'Alive state'
                                    ),
                                    value: getAliveStateLabel(
                                        entity.aliveState
                                    ),
                                },
                                {
                                    key: t(
                                        'c2a8db66914fbef9da089ea77ca28b65',
                                        'Expected new alive'
                                    ),
                                    value: (
                                        <EdsTableDatetimeSeconds
                                            value={entity.expectedNewAliveDate}
                                        />
                                    ),
                                },
                                {
                                    key: t(
                                        '532a9bf2fb21661bbe56136a270de97b',
                                        'Alive interval (HH:mm)'
                                    ),
                                    value: entity.deviceType?.testCallInterval,
                                },
                            ],
                            right: [
                                {
                                    key: t(
                                        '7f76bb7c283b8eed7ffcaad455f4ba2d',
                                        'Monitor alives'
                                    ),
                                    value: (
                                        <EdsTableState
                                            validState={true}
                                            invalidState={false}
                                            state={
                                                entity.deviceState
                                                    ?.checkControlMessages
                                            }
                                        />
                                    ),
                                },
                                {
                                    key: t(
                                        'd25fa8e5e93f31b95a19a9c4f21992e5',
                                        'Last alarm'
                                    ),
                                    value: (
                                        <EdsTableDatetimeSeconds
                                            value={entity.lastAlarmTimestamp}
                                        />
                                    ),
                                },
                                {
                                    key: t(
                                        '5688e40735c3736444c4d4a6d37799f4',
                                        'Expected batterylife (in days)'
                                    ),
                                    value: entity.deviceType
                                        ?.lifecycleBatteryInDays,
                                },
                            ],
                        },
                    }}
                />
            </EdsContainer>
        </EdsContainerGrid>
    );
};
