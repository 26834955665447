import './umo-document-form.scss';
import { Row } from '@carbon/react';
import {
    EdsFileUpload,
    EdsForm,
    EdsFormColumn,
    EdsFormGroup,
    FileUploadStatus,
} from '../../..';
import { forwardRef, useState } from 'react';
import {
    getFileSizeLabel,
    getLogger,
    useNotifications,
} from '../../../../features';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

const logger = getLogger('DeviceTypeImageForm');

const UmoDocumentForm = forwardRef((props, ref) => {
    const { showSuccess, showError } = useNotifications();
    const [filenameStatus, setFilenameStatus] = useState(FileUploadStatus.Edit);
    const { t } = useTranslation();
    const formDefinition = {
        document: {
            validation: {
                type: 'files',
                required: true,
                maxCount: 1,
                maxFileSize: props.maxFileSize,
                allowedTypes: props.allowedTypes,
            },
        },
    };

    const onSubmit = async (_event, form, isValid) => {
        if (!isValid) {
            throw false;
        }

        try {
            logger.log('File:', form.document);
            setFilenameStatus(FileUploadStatus.Uploading);
            const abortController = new AbortController();
            const response = await props.postDataCallback(
                props.entity?.id,
                form.document[0],
                (percentCompleted, remainingTime) => {
                    logger.log(
                        `File upload progress: completed: ${percentCompleted}% remaining: ${remainingTime}`
                    );
                },
                abortController
            );
            setFilenameStatus(FileUploadStatus.Complete);

            showSuccess({
                title: t('536a056b710e94b16169efd17a4a657b', 'Saved'),
                content: t(
                    '484b4c163b57b73647002eaad865133e',
                    "{{entity}} of '{{name}}' is saved",
                    {
                        entity: t(
                            'fdc3bdefb79cec8eb8211d2499e04704',
                            'Document'
                        ),
                        name:
                            props.entity?.person?.combinedName ||
                            props.entity?.name ||
                            t('e268443e43d93dab7ebef303bbe9642f', 'Account'),
                    }
                ),
            });

            props.tableRef?.current?.fetchInitData();

            return response;
        } catch (error) {
            setFilenameStatus(FileUploadStatus.Edit);

            let errors = error?.response?.data?.errors;
            if (
                !_.isEmpty(errors) &&
                _.isArray(errors) &&
                _.findIndex(errors, ['code', 'B1212']) !== -1
            ) {
                // <Entity> document with the same file name already exists
                let filename = form.document[0].name;
                showError({
                    title: t('cb5e100e5a9a3e7f6d1fd97512215282', 'Error'),
                    content: t(
                        '2bae1b96103a2d821115bb11d5196986',
                        "Document with filename '{{filename}}' already exists",
                        { filename }
                    ),
                });
            } else {
                showError({
                    title: t('cb5e100e5a9a3e7f6d1fd97512215282', 'Error'),
                    content: t(
                        'df0a9d46baf7315909e4389a04786e3d',
                        'Oops something went wrong'
                    ),
                });
            }

            throw error;
        }
    };

    let supported = [];
    if (props.allowedTypes.find((type) => type === 'image/jpeg')) {
        supported.push(t('ef9432eb38eae95b92dd756aae763834', 'JPEG images'));
    }
    if (props.allowedTypes.find((type) => type === 'image/png')) {
        supported.push(t('0c9fce3438ec071c3031b2b4763615e1', 'PNG images'));
    }
    if (props.allowedTypes.find((type) => type === 'image/tiff')) {
        supported.push(t('38abf44e31a65cd3446fc0dd83174e44', 'TIFF images'));
    }
    if (props.allowedTypes.find((type) => type === 'application/pdf')) {
        supported.push(t('322696d3ad9cbf4e3ea7afd808fc3945', 'PDF documents'));
    }
    if (props.allowedTypes.find((type) => type === 'text/plain')) {
        supported.push(t('e4589efff654d91e26b43333dbf41425', 'Text files'));
    }
    if (
        props.allowedTypes.find(
            (type) =>
                type.indexOf('msword') !== -1 ||
                type.indexOf('wordprocessingml') !== -1
        )
    ) {
        supported.push(t('a170e748442abc5615c6f2d7f36fdc29', 'Word documents'));
    }
    if (
        props.allowedTypes.find(
            (type) =>
                type.indexOf('excel') !== -1 ||
                type.indexOf('spreadsheet') !== -1
        )
    ) {
        supported.push(t('d87e3271ddf65038705bdcd2636b2686', 'Spreadsheets'));
    }
    if (
        props.allowedTypes.find(
            (type) =>
                type === 'application/octet-stream' ||
                type === 'application/vnd.ms-outlook' ||
                type === 'message/rfc822'
        )
    ) {
        supported.push(t('066c78929737660741a1f59cc44a55ec', 'Email messages'));
    }

    return (
        <div className="umo-document-form">
            <EdsForm
                hideDefaultSubmit={props.hideDefaultSubmit}
                initValues={{}}
                formDefinition={formDefinition}
                onSubmit={onSubmit}
                ref={ref}
            >
                <EdsFormGroup>
                    <Row>
                        <EdsFormColumn>
                            <EdsFileUpload
                                name="document"
                                description={t(
                                    '056162bd6ab6d20272c12e85bdfe617c',
                                    'Max file size is {{fileSize}}.',
                                    {
                                        fileSize: getFileSizeLabel(
                                            props.maxFileSize
                                        ),
                                    }
                                )}
                                filenameStatus={filenameStatus}
                                multiple={false}
                                accept={props.allowedTypes}
                            />
                        </EdsFormColumn>
                        <EdsFormColumn>
                            {!_.isEmpty(supported) && (
                                <>
                                    <p className="supported-files-label">
                                        {t(
                                            'a4c033fac6cd3dea97bb223d46bc72de',
                                            'Supported files:'
                                        )}
                                    </p>
                                    <ul>
                                        {supported.map((keyword) => (
                                            <li
                                                key={keyword}
                                                className="supported-file-item"
                                            >
                                                {' • ' + keyword}
                                            </li>
                                        ))}
                                    </ul>
                                </>
                            )}
                        </EdsFormColumn>
                    </Row>
                </EdsFormGroup>
            </EdsForm>
        </div>
    );
});

UmoDocumentForm.displayName = 'UmoDocumentForm';

export default UmoDocumentForm;
