import { useTranslation } from 'react-i18next';
import {
    EdsContainer,
    EdsDataGrid,
    EdsSecretLabel,
    getFullStreetName,
} from '../../../eds';
import _ from 'lodash';

export const UmoResidenceInfoGrid = ({ residence, ...props }) => {
    const { t } = useTranslation();

    const isUKAddressFormat =
        residence?.address?.country?.isUKAddressFormat ?? false;

    const getCombinedAddress = (address) => {
        const parts = [getFullStreetName(address)];

        if (!_.isEmpty(address.apartmentNumber)) {
            if (address?.country?.isUKAddressFormat) {
                parts.push(`${address.apartmentNumber}, `);
            } else {
                parts.push(`(${address.apartmentNumber})`);
            }
        }

        if (address?.country?.isUKAddressFormat) {
            return parts.reverse().join(' ');
        } else {
            return parts.join(' ');
        }
    };

    const getPCCity = (address) => {
        const parts = [];

        if (!_.isEmpty(address.postalCode)) {
            parts.push(address.postalCode);
        }

        if (!_.isEmpty(address?.city?.name)) {
            parts.push(address?.city?.name);
        }

        return parts.join(', ');
    };

    return (
        <EdsContainer
            title={t('156562daa905d40455b1b62cc3cf8303', 'Residence info')}
            {...props}
        >
            <EdsDataGrid
                gridData={{
                    styles: {
                        left: {
                            key: {
                                width: '400px',
                            },
                        },
                        right: {
                            key: {
                                width: '400px',
                            },
                        },
                    },
                    rows: {
                        left: [
                            ...(isUKAddressFormat
                                ? [
                                      {
                                          key: t(
                                              '8d6c792fc6a9a9019f65fe22d4871e82',
                                              'Address name'
                                          ),
                                          value: residence?.address
                                              ?.addressName,
                                      },
                                  ]
                                : []),
                            {
                                key: t(
                                    '884d9804999fc47a3c2694e49ad2536a',
                                    'Address'
                                ),
                                value: getCombinedAddress(residence?.address),
                            },

                            {
                                key: t(
                                    '9d31fe5813d46b26ac8777a761e19e1c',
                                    'PC/City'
                                ),
                                value: getPCCity(residence?.address),
                            },
                            {
                                key: t(
                                    '2d02d77d09fdf4c4466181594b8d8739',
                                    'Residence type'
                                ),
                                value: residence?.residenceType?.name,
                            },
                            {
                                key: t(
                                    '911c185c8f3c475cdca2ef9cf12166da',
                                    'Remark'
                                ),
                                value: residence?.keyLocationRemarks,
                            },
                        ],
                        right: [
                            ...(isUKAddressFormat //Show empty field for field alignment if Address name is visible
                                ? [{}]
                                : []),
                            {
                                key: t(
                                    '25aa6d135647ba9f0a08b7d6d4d35829',
                                    'Point of entry'
                                ),
                                value: residence?.pointOfEntry?.name,
                            },
                            {
                                key: t(
                                    'f4a88de657502bf4c2c24ffc31b5826b',
                                    'Key code'
                                ),
                                value: (
                                    <EdsSecretLabel
                                        label={residence?.keyCode}
                                        showSecretTooltipLabel={t(
                                            'dbc09dfacc1e086379dfafbae53c26ba',
                                            'Click to hide key code'
                                        )}
                                        hideSecretTooltipLabel={t(
                                            '12c9675e997a4aceee1ec9d944189cc9',
                                            'Click to show key code'
                                        )}
                                    />
                                ),
                            },
                            {
                                key: t(
                                    'f146dfb63e02f43297d4c2d9733d3a54',
                                    'Key number'
                                ),
                                value: residence?.keyNumber,
                            },
                            {
                                key: t(
                                    '1970fa927314617c668affa49d7c51dd',
                                    'Key location'
                                ),
                                value: residence?.keyLocation?.name,
                            },
                        ],
                    },
                }}
            />
        </EdsContainer>
    );
};
