import { useRouteLoaderData } from 'react-router-dom';
import {
    EdsContainer,
    EdsContainerGrid,
    UmoDocumentsTable,
} from '../../../components';

import _ from 'lodash';
import {
    getAlarmProcedureDocuments,
    getAlarmProcedureDocumentUrl,
} from '../../../features';
import { useTranslation } from 'react-i18next';

function ProceduresDetailsTab() {
    const { t } = useTranslation();
    const { accountId, procedure } = useRouteLoaderData(
        'alarm-detail/procedures/id'
    );

    if (_.isNil(procedure) || _.isEmpty(procedure)) {
        return <EdsContainerGrid />;
    }

    return (
        <EdsContainerGrid>
            <EdsContainer
                withBottomPadding={true}
                title={t('662609908ab8e0f372d83dea3511370b', 'Procedure')}
            >
                <p className="only-text">{procedure.procedure}</p>
            </EdsContainer>

            <UmoDocumentsTable
                entity={procedure}
                accountId={accountId}
                getDataCallback={getAlarmProcedureDocuments}
                getUrlCallback={getAlarmProcedureDocumentUrl}
                onDeleteClick={undefined}
                onEditClick={undefined}
                onHeaderAddClick={undefined}
            />
        </EdsContainerGrid>
    );
}

export default ProceduresDetailsTab;
