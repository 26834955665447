import {
    createContext,
    memo,
    useCallback,
    useContext,
    useEffect,
    useReducer,
} from 'react';
import { getLogger } from '../utils';
import { useChannels } from '../channels';
import { AlarmsActions, alarmsReducer } from './reducer';
import { useNotifications } from '../notifications';
import _ from 'lodash';
import { v4 as uuid } from 'uuid';
import { postAcceptAlarm } from '../uds';
import { useTranslation } from 'react-i18next';
import { NotificationType } from '../notifications/context';

const logger = getLogger('AlarmsProvider');

const AlarmsContext = createContext({
    eventListeners: [],
    addEventListener: () => null,
    removeEventListener: () => null,
});

export const AlarmEvents = {
    NewAlarm: 'new_alarm',
    AlarmAccepted: 'alarm_accepted',
    AlarmOnHold: 'alarm_on_hold',
    AlarmResumed: 'alarm_resumed',
    AlarmClosed: 'alarm_closed',
};

export const AlarmsProvider = memo(({ children }) => {
    const { t } = useTranslation();
    const { channel } = useChannels();
    const { showInfo } = useNotifications();
    const [state, dispatch] = useReducer(alarmsReducer, {
        eventListeners: [],
    });

    useEffect(() => {
        if (channel) {
            logger.log('Set event Channel Listeners');
            const alarmRef = channel.on('alarm', (payload) => {
                logger.log(`channel::alarm::events`, payload);

                state.eventListeners
                    .filter(
                        (listener) =>
                            !_.isUndefined(payload.event) &&
                            listener.eventName === payload.event
                    )
                    .forEach((listener) => {
                        logger.log('Execute listener', listener);
                        if (!_.isFunction(listener.func)) {
                            return;
                        }
                        if (!_.isUndefined(payload.event_data)) {
                            listener.func(payload.event_data);
                        } else {
                            listener.func(payload);
                        }
                    });
            });
            return () => {
                logger.log('Remove channel event listener');
                channel.off('alarm', alarmRef);
            };
        }
    }, [channel, state]);

    // global new alarm event handler to show app wide notification
    useEffect(() => {
        if (channel) {
            logger.log('Set global alarm Channel Listeners');
            const ref = channel.on('alarm', (payload) => {
                if (payload.event !== 'new_alarm') {
                    return;
                }

                logger.log('channel::new_alarm::global', payload);
                showInfo({
                    type: NotificationType.Desktop,
                    title: t('b235e572f591b0838ae85279842bdd5f', 'New Alarm'),
                    content: `${payload.event_data.data.customer_metadata.criterion_name}: ${payload.event_data.data.customer_metadata.client_name} (${payload.event_data.data.device_code})`,
                    ttl: 0,
                    actionButtonLabel: t(
                        '4abe77c201ff11663ccdf52fd6ecea86',
                        'Accept'
                    ),
                    onActionButtonClick: (removeNotification) => {
                        postAcceptAlarm(payload.event_data.data.alarm_id).then(
                            () => removeNotification()
                        );
                    },
                });
            });
            return () => {
                logger.log("Remove channel event listener on global 'alarm'");
                channel.off('alarm', ref);
            };
        }
    }, [channel, showInfo, t]);

    const addEventListener = useCallback((eventName, func) => {
        logger.log('Add Queue Listener', eventName, func);
        if (!_.isFunction(func)) {
            return;
        }
        const id = uuid();
        dispatch({
            type: AlarmsActions.AddEventListener,
            payload: {
                id,
                eventName,
                func,
            },
        });
        return id;
    }, []);
    const removeEventListener = useCallback((id) => {
        logger.log('Remove Queue Listener', id);
        if (_.isUndefined(id)) {
            return;
        }
        dispatch({
            type: AlarmsActions.RemoveEventListener,
            payload: { id },
        });
    }, []);

    return (
        <AlarmsContext.Provider
            value={{
                ...state,
                addEventListener,
                removeEventListener,
            }}
        >
            {children}
        </AlarmsContext.Provider>
    );
});

AlarmsProvider.displayName = 'AlarmProvider';

export const useAlarms = () => useContext(AlarmsContext);
export const AlarmsConsumer = AlarmsContext.Consumer;
