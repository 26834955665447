import _ from 'lodash';

export const EdsTableReducerActions = {
    SetRows: 'SET_ROWS',
    InsertRow: 'INSERT_ROW',
    UpdateRow: 'UPDATE_ROW',
    RemoveRow: 'REMOVE_ROW',
};

export const EdsTableReducer = (state, action) => {
    switch (action.type) {
        case EdsTableReducerActions.SetRows: {
            return {
                ...state,
                rows: action.payload,
            };
        }
        case EdsTableReducerActions.InsertRow: {
            if (action.payload.end) {
                return {
                    ...state,
                    rows: [...state.rows, action.payload.row],
                };
            }
            return {
                ...state,
                rows: [action.payload.row, ...state.rows],
            };
        }
        case EdsTableReducerActions.UpdateRow: {
            let index = state.rows.findIndex(
                (item) => item.id === `${action.payload.id}`
            );
            if (_.isUndefined(index)) {
                return state;
            }

            let rows = state.rows;
            rows[index] = action.payload.row;

            return {
                ...state,
                rows: rows,
            };
        }
        case EdsTableReducerActions.RemoveRow: {
            return {
                ...state,
                rows: state.rows.filter(
                    (item) => item.id !== `${action.payload.id}`
                ),
            };
        }
    }
};
