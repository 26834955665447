import { useTranslation } from 'react-i18next';
import { EdsDataGrid } from '../../../eds';
import _ from 'lodash';

export const UmoDeviceManagerDetailGrid = ({ deviceManager, phoneNumbers }) => {
    const { t } = useTranslation();

    const getPhoneNumbers = () => {
        if (_.isEmpty(phoneNumbers)) {
            return undefined;
        }

        return (
            <ul>
                {phoneNumbers.map((index) => (
                    <li key={index.id}>{index.number}</li>
                ))}
            </ul>
        );
    };

    return (
        <EdsDataGrid
            gridData={{
                styles: {
                    left: {
                        key: {
                            width: '400px',
                        },
                    },
                },
                rows: {
                    left: [
                        {
                            key: t('b068931cc450442b63f5b3d276ea4297', 'Name'),
                            value: deviceManager.name,
                        },
                        {
                            key: t(
                                '884d9804999fc47a3c2694e49ad2536a',
                                'Address'
                            ),
                            value: deviceManager.combinedAddressField,
                        },
                        {
                            key: t('0c83f57c786a0b4a39efab23731c7ebc', 'Email'),
                            value: deviceManager.email,
                        },
                        {
                            key: t(
                                '15f187eef641853ab7d51bf83f1ad348',
                                'Contact person'
                            ),
                            value: deviceManager.contactPerson?.combinedName,
                        },
                        {
                            key: t(
                                '608ae100f280b757a3a7483ff3a25905',
                                'Phone numbers'
                            ),
                            value: getPhoneNumbers(),
                        },
                        {
                            key: t(
                                '911c185c8f3c475cdca2ef9cf12166da',
                                'Remark'
                            ),
                            value: deviceManager.remark,
                        },
                    ],
                },
            }}
        />
    );
};
