import { useTranslation } from 'react-i18next';
import {
    EdsOptionalLink,
    EdsSortDirection,
    EdsTable,
    EdsTableFilterType,
    EdsTableConsumerState,
} from '../../../../components';
import {
    ClientCreatePermissionSet,
    clientSearchFilterStore,
    getClientSearch,
    getClientStatuses,
    getContractEndingReasons,
    getGenderLabel,
    getMedicalPriorities,
    getSchemes,
    getTagCategories,
} from '../../../../features';
import _ from 'lodash';

export const UmoSearchClientTable = ({ baseFilter, ...props }) => {
    const { t } = useTranslation();

    const getDataCallback = async ({ $filter, ...params }) => {
        return getClientSearch({
            ...params,
            $filter: addBaseFilter($filter),
        });
    };

    const addBaseFilter = (filter) => {
        const filterParts = [];

        if (!_.isUndefined(filter)) {
            filterParts.push(filter);
        }

        if (baseFilter) {
            filterParts.push(baseFilter);
        }

        if (_.isEmpty(filterParts)) {
            return undefined;
        }

        return filterParts.join(' and ');
    };

    const mappingCallback = (data) => {
        return {
            id: data.id,
            state: (
                <EdsTableConsumerState
                    state={data.clientStatus?.consumerState?.name}
                    label={data?.clientStatus?.name}
                />
            ),
            name: (
                <EdsOptionalLink
                    id={data?.id}
                    to={`/crm/client/${data?.id}`}
                    label={data.person?.combinedName}
                />
            ),
            gender: getGenderLabel(t, data.person?.gender.toLowerCase()),
            address: (
                <EdsOptionalLink
                    id={data?.residenceId}
                    to={`/crm/residence/${data?.residenceId}`}
                    label={data.combinedAddressField}
                />
            ),
            group: data?.group?.name,
            defaultDevice: (
                <EdsOptionalLink
                    id={data?.defaultDisplayedDeviceId}
                    to={`/crm/device/${data?.defaultDisplayedDeviceId}`}
                    label={data.defaultDisplayedDeviceCode}
                />
            ),
        };
    };

    const headers = [
        {
            key: 'state',
            header: t('9ed39e2ea931586b6a985a6942ef573e', 'State'),
            sort: 'clientStatus/name',
        },
        {
            key: 'name',
            header: t('b068931cc450442b63f5b3d276ea4297', 'Name'),
            sort: 'person/lastName,person/prefix/name,person/firstName,person/insertion',
            isDefaultSort: EdsSortDirection.Ascending,
        },
        {
            key: 'gender',
            header: t('cc90f1913b83d255b95be0e0fea6d576', 'Gender'),
            sort: 'person/gender',
        },
        {
            key: 'address',
            header: t('884d9804999fc47a3c2694e49ad2536a', 'Address'),
            // Sort on address is currently not supported as sort is a combined
            // field. If users actually need this, the UDS team shoud support
            // sorting on combinedAddressField.
            // sort: 'combinedAddressField',
        },
        {
            key: 'group',
            header: t('db0f6f37ebeb6ea09489124345af2a45', 'Group'),
            sort: 'group/name',
        },
        {
            key: 'defaultDevice',
            header: t('6c4c3c1e466c26dca47ded5d993858ea', 'Default device'),
            sort: 'defaultDisplayedDeviceCode',
        },
    ];

    return (
        <EdsTable
            searchFilterStore={clientSearchFilterStore}
            searchTooltipKeywords={[
                t('d290ae18b3c8c67f619a3c87b398f323', 'Last name'),
                t('1b64f409e392564f28b5b62e894293ab', 'Birthdate'),
                t('533c6e90083c9312631bd50350d09c2f', 'Social security number'),
                t('57d50ee4873d42049cc021a5df38b750', 'Device code'),
                t('1f4a9685e37e4f940d07a9f6b43dc83c', 'Street'),
                t('c8a03a9875d7fc4c023e1bebb23d07af', 'Postal code'),
                t('4ed5d2eaed1a1fadcc41ad1d58ed603e', 'City'),
                t('9b88e58612797d6b989681a16621ad63', 'Phone number'),
            ]}
            availableFilters={[
                {
                    id: 'clientStatusId',
                    name: t(
                        '698e3cb990e8de8a48b4c4c961eeea53',
                        'Client status'
                    ),
                    type: EdsTableFilterType.MultiSelect,
                    getDataCallback: async () => getClientStatuses(),
                    // todo UMO-454: from doc: quickselect based on ConsumerState??
                },
                {
                    id: 'person/lastName',
                    name: t('d290ae18b3c8c67f619a3c87b398f323', 'Last name'),
                    type: EdsTableFilterType.Text,
                    caseSensitive: false,
                },
                {
                    id: 'person/firstName',
                    name: t('9461dfec80b7485cd052414128668fa8', 'First name'),
                    type: EdsTableFilterType.Text,
                    caseSensitive: false,
                },
                {
                    id: 'person/birthDate',
                    name: t('1b64f409e392564f28b5b62e894293ab', 'Birthdate'),
                    type: EdsTableFilterType.Date,
                },
                {
                    id: 'clientSubscriptionNumber',
                    name: t(
                        '9d0387260c4716615297f599b8adec9a',
                        'Subscription number'
                    ),
                    type: EdsTableFilterType.Number,
                },
                {
                    id: 'socialSecurityNumber',
                    name: t(
                        '533c6e90083c9312631bd50350d09c2f',
                        'Social security number'
                    ),
                    type: EdsTableFilterType.Text,
                },
                {
                    id: 'defaultEmailAddress',
                    name: t('0c83f57c786a0b4a39efab23731c7ebc', 'Email'),
                    type: EdsTableFilterType.Email,
                    caseSensitive: false,
                },
                {
                    id: '',
                    name: t('db0f6f37ebeb6ea09489124345af2a45', 'Group'),
                    type: EdsTableFilterType.Group,
                },
                {
                    id: 'startDate',
                    name: t('46188e80453520b55cd82a67950f660e', 'Start date'),
                    type: EdsTableFilterType.DateTimeRange,
                },
                {
                    id: 'endDate',
                    name: t('f49c17d504a2e8f8e29454a6d6ce9ecd', 'End date'),
                    type: EdsTableFilterType.DateTimeRange,
                },
                {
                    id: 'insertDate',
                    name: t('04bc21c2a8622f27a748600d90461f93', 'Created date'),
                    type: EdsTableFilterType.DateRange,
                },
                {
                    id: 'updateDate',
                    name: t('976e620685715069bdf7d75f73fbcacb', 'Updated date'),
                    type: EdsTableFilterType.DateRange,
                },
                {
                    id: 'isLoneworker',
                    name: t('98362d98323b4a89d49b47db4bc03060', 'Loneworker'),
                    type: EdsTableFilterType.YesNo,
                },
                {
                    id: 'defaultDisplayedDeviceCode',
                    name: t('57d50ee4873d42049cc021a5df38b750', 'Device code'),
                    type: EdsTableFilterType.Range,
                },
                {
                    id: 'residence/address/',
                    name: t('d5189de027922f81005951e6efe0efd5', 'Location'),
                    type: EdsTableFilterType.Location,
                },
                {
                    id: 'residence/address/streetName',
                    name: t('1f4a9685e37e4f940d07a9f6b43dc83c', 'Street'),
                    type: EdsTableFilterType.Text,
                    caseSensitive: false,
                },
                {
                    id: 'residence/address/houseNumber',
                    name: t('61a0a33cf99a58d9cad0d5ed6deae269', 'House number'),
                    type: EdsTableFilterType.Text,
                },
                {
                    id: 'residence/address/postalCode',
                    name: t('c8a03a9875d7fc4c023e1bebb23d07af', 'Postal code'),
                    type: EdsTableFilterType.Text,
                },
                {
                    id: 'residence/schemeId',
                    name: t('41323917ef8089432959a3c33269debf', 'Scheme'),
                    type: EdsTableFilterType.Dropdown,
                    getDataCallback: async () => getSchemes(),
                },
                {
                    id: 'contractEndingReasonId',
                    name: t(
                        '9af967f3050f41ff2cffd8536b87f8d2',
                        'Contract ending reason'
                    ),
                    type: EdsTableFilterType.Dropdown,
                    getDataCallback: async () => getContractEndingReasons(),
                },
                {
                    id: 'medicalPriorityId',
                    name: t(
                        '64df27ddb333384955109454e26e112f',
                        'Medical priority'
                    ),
                    type: EdsTableFilterType.Dropdown,
                    getDataCallback: async () => getMedicalPriorities(),
                },
                {
                    id: 'tag',
                    name: t('e4d23e841d8e8804190027bce3180fa5', 'Tag'),
                    type: EdsTableFilterType.Tag,
                    getDataCallback: async () =>
                        getTagCategories({
                            IsValidForClients: true,
                            IsActive: true,
                        }),
                },
            ]}
            headers={headers}
            searchClientSide={false}
            getDataCallback={getDataCallback}
            mappingCallback={mappingCallback}
            permissions={{
                new: ClientCreatePermissionSet,
            }}
            {...props}
        ></EdsTable>
    );
};
