export const getMapMarkers = (positions) => {
    let markers = [];

    for (const index in positions) {
        const position = positions[index];

        markers.push({
            key: position.id,
            location: {
                lat: position.latitude,
                lng: position.longitude,
                altitude: position.altitude,
            },
            details: position,
        });
    }
    return markers;
};
