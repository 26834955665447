import {
    createSearchFilterStoreFunctions,
    getRequest,
    postRequest,
} from '../api';

const basePath = '/alarms';

export const getAlarmSearch = async (search) => {
    return getRequest(`${basePath}/search`, search);
};

export const getAlarm = async (id) => {
    return getRequest(`${basePath}/${id}`);
};

export const getAlarmActions = async (id) => {
    return getRequest(`${basePath}/${id}/alarmActions`);
};
export const getAlarmDetails = async (id) => {
    return getRequest(`${basePath}/${id}/alarmDetails`);
};

export const getAlarmReasons = async (id) => {
    return getRequest(`${basePath}/${id}/alarmReasons`);
};

export const getAlarmRenderedHelp = async (id) => {
    return getRequest(`${basePath}/${id}/alarmRenderedHelp`);
};

export const getCareRegistrations = async (id) => {
    return getRequest(`${basePath}/${id}/careRegistrations`);
};

export const getAlarmDevicePositions = async (id) => {
    return getRequest(`${basePath}/${id}/alarmDevicePositions`);
};

export const getAlarmQueue = async () => {
    return getRequest(`${basePath}/queue`);
};

export const getActiveAlarms = async () => {
    return getRequest(`${basePath}/active`);
};

export const postAcceptAlarm = async (id) => {
    return postRequest(`${basePath}/accept`, {
        alarm_id: id,
    });
};

export const postHoldAlarm = async (id) => {
    return postRequest(`${basePath}/hold`, {
        alarm_id: id,
    });
};

export const postResumeAlarm = async (id) => {
    return postRequest(`${basePath}/resume`, {
        alarm_id: id,
    });
};

export const postCloseAlarm = async (id) => {
    return postRequest(`${basePath}/close`, {
        alarm_id: id,
    });
};

export const getAlarmState = async (id) => {
    return getRequest(`${basePath}/${id}/state`);
};

export const searchFilterStore = {
    ...createSearchFilterStoreFunctions(basePath, 'alarms'),
};
