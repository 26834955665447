import { createBrowserRouter, Outlet, redirect } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import App from './app';
import AuthRoutes from './routes/auth';
import CrmRoutes from './routes/crm';
import { EdsRouteErrorPage, EdsUiShell } from './components/';
import ProtectedRoute from './routes/protected-route';
import { ProviderTree } from './provider-tree';
import axios from './features/utils/axios';
import { accountIdHeaderName, AccountIdSelector } from './features';
import ScrollToTop from './features/utils/scroll-to-top';
import { ErrorBoundary } from './features/utils/error-boundary';
import AlarmRoutes from './routes/alarm';
import { Suspense } from 'react';

const sentryCreateBrowserRouter =
    Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const routes = sentryCreateBrowserRouter([
    {
        path: '/',
        element: (
            <ProviderTree>
                <ScrollToTop />

                <ErrorBoundary>
                    <App />
                </ErrorBoundary>
            </ProviderTree>
        ),
        errorElement: <EdsRouteErrorPage />,
        children: [
            {
                index: true,
                // todo [UMO-544]: revert back to /alarm when alarm queue page is done
                loader: () => redirect('/account'),
            },
            AuthRoutes,
            {
                path: '',
                element: (
                    <ProtectedRoute>
                        <EdsUiShell>
                            <AccountIdSelector>
                                <Suspense>
                                    <Outlet />
                                </Suspense>
                            </AccountIdSelector>
                        </EdsUiShell>
                    </ProtectedRoute>
                ),
                children: [
                    {
                        path: 'alarm',
                        children: [
                            {
                                index: true,
                                loader: () => redirect('queue'),
                            },
                            AlarmRoutes,
                        ],
                    },
                    {
                        path: 'account',
                        // to supress warning about needing element cause of the above outlet
                        element: null,
                    },
                    {
                        path: 'account/:accountId',
                        loader: ({ params }) => {
                            axios.defaults.headers.common[accountIdHeaderName] =
                                params.accountId;

                            return null;
                        },
                        shouldRevalidate: () => false,
                        children: [
                            {
                                index: true,
                                loader: () => redirect('crm'),
                            },
                            CrmRoutes,
                        ],
                    },
                ],
            },
        ],
    },
]);
