import { useTranslation } from 'react-i18next';
import { EdsContainer, EdsContainerGrid, EdsDataGrid } from '../../../eds';

export const UmoClientMedicalInfoGrid = ({ entity, ...props }) => {
    const { t } = useTranslation();

    return (
        <EdsContainerGrid>
            <EdsContainer
                title={t(
                    '3d21170a89cfd8231b689f645e51c3f5',
                    'General medical info'
                )}
                {...props}
            >
                <EdsDataGrid
                    gridData={{
                        styles: {
                            left: {
                                key: {
                                    width: '400px',
                                },
                            },
                            right: {
                                key: {
                                    width: '400px',
                                },
                            },
                        },
                        rows: {
                            left: [
                                {
                                    key: t(
                                        '64df27ddb333384955109454e26e112f',
                                        'Medical priority'
                                    ),
                                    value: entity?.medicalPriority?.name,
                                },
                                {
                                    key: t(
                                        '750408fa0d92c890d67b337f678caee4',
                                        'Doctor reference'
                                    ),
                                    value: entity?.doctorReference?.name,
                                },
                            ],
                            right: [
                                {
                                    key: t(
                                        'de7b9ce17b572a802f8ae8c7dafafadc',
                                        'Location medicines'
                                    ),
                                    value: entity?.storage,
                                },
                            ],
                        },
                    }}
                />
            </EdsContainer>
        </EdsContainerGrid>
    );
};
