import { useTranslation } from 'react-i18next';
import './umo-pdf-view.scss';

export const UmoPdfView = (props) => {
    const { t } = useTranslation();

    if (!props?.src) {
        return t('168f6271829b5ac458c6855b5c77faec', 'Loading failed');
    }

    let src = props.src;
    if (props.noCaching) {
        src += '&t=' + new Date().getTime();
    }

    return (
        <object className="umo-pdf-view" type="application/pdf" data={src} />
    );
};
