import './eds-table-criterion.scss';
import _ from 'lodash';
import {
    getCarbonIcon,
    getDefaultsForCriterionIcon,
    isValidHexColor,
} from '../../../../features';

export function EdsTableCriterion({ label, icon, color, criterion }) {
    const { icon: defaultIcon, color: defaultColor } =
        getDefaultsForCriterionIcon(criterion);

    const Icon = ({ iconName }) => {
        const Base = getCarbonIcon(iconName, defaultIcon);
        return <Base color="#FFFFFF" />;
    };

    const getBackgrounColor = () => {
        if (_.isNil(color) || _.isEmpty(color) || !isValidHexColor(color)) {
            return defaultColor;
        }
        return color;
    };

    return (
        <div className="eds-table-criterion">
            <div
                className={`eds-table-criterion-icon`}
                style={{ backgroundColor: getBackgrounColor() }}
            >
                <Icon iconName={icon} />
            </div>
            <div>
                ({criterion}) {label}
            </div>
        </div>
    );
}
