import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { EdsContainerTable, EdsTableDatetime } from '../../..';

export const UmoDeviceAliveCalls = ({ getDataCallback, ...props }) => {
    const { t } = useTranslation();
    const aliveCallsTableRef = useRef(null);

    const getData = async () => {
        return await getDataCallback();
    };

    return (
        <EdsContainerTable
            ref={aliveCallsTableRef}
            title={t('9df1c1a7687ddbb2cf6cd1457812ee7d', 'Alive calls')}
            headers={[
                {
                    key: 'receivedOn',
                    header: t(
                        '01c76cca45a8dc7a3cd3097e734ee65b',
                        'Received on'
                    ),
                    sort: 'timestamp',
                },
                {
                    key: 'deviceCode',
                    header: t(
                        '57d50ee4873d42049cc021a5df38b750',
                        'Device code'
                    ),
                    sort: 'deviceCode',
                },
                {
                    key: 'cli',
                    header: t('0898b22730d57afcd394d8e4889ece4a', 'CLI'),
                    sort: 'cli',
                },
            ]}
            getDataCallback={getData}
            mappingCallback={(responseData) => {
                return {
                    id: responseData.id,
                    receivedOn: (
                        <EdsTableDatetime value={responseData.timestamp} />
                    ),
                    timestamp: responseData.timestamp,
                    deviceCode: responseData.deviceCode,
                    cli: responseData.cli,
                };
            }}
            {...props}
        />
    );
};
