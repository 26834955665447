import {
    EdsPage,
    EdsContainerTable,
    EdsAlarmStatus,
    EdsTableCriterion,
    EdsTableDatetimeSeconds,
} from '../../../components';
import { useTranslation } from 'react-i18next';
import {
    getAlarmQueue,
    getLogger,
    postAcceptAlarm,
    postCloseAlarm,
    postHoldAlarm,
    postResumeAlarm,
    AlarmEvents,
    useAlarms,
} from '../../../features';
import './queue-page.scss';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import {
    Close,
    ContinueFilled,
    PauseFilled,
    PlayFilledAlt,
} from '@carbon/icons-react';

const logger = getLogger('AlarmQueuePage');

function QueuePage() {
    const tableRef = useRef(null);
    const { t } = useTranslation();
    const { addEventListener, removeEventListener } = useAlarms();
    const navigate = useNavigate();

    const getTableRowStyle = (rowData) => {
        if (!_.isObject(rowData) || _.isNil(rowData?.assignee)) {
            return undefined;
        }

        return 'eds-table-row-alarm-assigned';
    };

    useEffect(() => {
        const updateAlarm = (alarm) => {
            logger.log('update alarm queue', alarm);
            tableRef.current?.updateTableRow(alarm);
        };

        const newAlarmEventId = addEventListener(
            AlarmEvents.NewAlarm,
            (alarm) => {
                logger.log('new alarm queue', alarm);
                tableRef.current?.insertTableRow(alarm);
            }
        );
        const alarmAcceptedEventId = addEventListener(
            AlarmEvents.AlarmAccepted,
            updateAlarm
        );
        const alarmHoldEventId = addEventListener(
            AlarmEvents.AlarmOnHold,
            updateAlarm
        );
        const alarmResumedEventId = addEventListener(
            AlarmEvents.AlarmResumed,
            updateAlarm
        );
        const alarmClosedRef = addEventListener(
            AlarmEvents.AlarmClosed,
            (alarm) => {
                logger.log('remove alarm from queue', alarm);
                tableRef.current?.removeTableRow(alarm);
            }
        );
        return () => {
            removeEventListener(newAlarmEventId);
            removeEventListener(alarmAcceptedEventId);
            removeEventListener(alarmHoldEventId);
            removeEventListener(alarmResumedEventId);
            removeEventListener(alarmClosedRef);
        };
    }, [addEventListener, removeEventListener]);

    return (
        <EdsPage title={t('3517d17f97589f6ee011e4d64ba4ec90', 'Alarm queue')}>
            <EdsContainerTable
                ref={tableRef}
                title={null}
                getTableRowStyleCallback={getTableRowStyle}
                headers={[
                    {
                        key: 'criterion',
                        header: t(
                            '4d4b370ab9b3dbca1ccdb1a5cca6a728',
                            'Criterion'
                        ),
                    },
                    {
                        key: 'timeStamp',
                        header: t(
                            '01c76cca45a8dc7a3cd3097e734ee65b',
                            'Received on'
                        ),
                    },
                    {
                        key: 'account_name',
                        header: t(
                            'e268443e43d93dab7ebef303bbe9642f',
                            'Account'
                        ),
                    },
                    {
                        key: 'name',
                        header: t(
                            '9fd352e406ac9fd2164c0ed50b35932b',
                            'Client name'
                        ),
                    },
                    {
                        key: 'medicalPriority',
                        header: t(
                            '64df27ddb333384955109454e26e112f',
                            'Medical priority'
                        ),
                    },
                    {
                        key: 'device',
                        header: t('913f9c49dcb544e2087cee284f4a00b7', 'Device'),
                    },
                    {
                        key: 'status',
                        header: t('9acb44549b41563697bb490144ec6258', 'Status'),
                    },
                ]}
                getDataCallback={getAlarmQueue}
                mappingCallback={(responseData) => {
                    return {
                        id: responseData.id,
                        timeStamp: (
                            <EdsTableDatetimeSeconds
                                value={responseData.data.timestamp}
                            />
                        ),
                        criterion: (
                            <EdsTableCriterion
                                label={
                                    responseData?.data.customer_metadata
                                        ?.criterion_name
                                }
                                criterion={responseData?.data?.criterion_number}
                                icon={
                                    responseData?.data.customer_metadata
                                        ?.criterion_icon
                                }
                                color={
                                    responseData?.data.customer_metadata
                                        ?.criterion_color
                                }
                            />
                        ),
                        name: responseData?.data.customer_metadata?.client_name,
                        account_name:
                            responseData?.data.customer_metadata?.account_name,
                        priority:
                            responseData?.data.customer_metadata?.priority,
                        medicalPriority:
                            responseData?.data.customer_metadata
                                ?.medical_priority,
                        device: responseData?.data.device_code,
                        status: (
                            <EdsAlarmStatus
                                assignee={responseData?.assigned_agent?.name}
                                status={responseData?.status}
                                startDate={responseData.data.timestamp}
                            />
                        ),
                        assignee: responseData?.data.assignee,
                    };
                }}
                onRowClick={(data) => {
                    navigate(`/alarm/details/${data.id}`);
                }}
                // todo show button based on row data
                customActionButtons={[
                    {
                        label: 'Accept alarm',
                        icon: <PlayFilledAlt />,
                        onClick: async (row) => {
                            await postAcceptAlarm(row.id);
                        },
                    },
                    {
                        label: 'Hold alarm',
                        icon: <PauseFilled />,
                        onClick: async (row) => {
                            await postHoldAlarm(row.id);
                        },
                    },
                    {
                        label: 'Resume alarm',
                        icon: <ContinueFilled />,
                        onClick: async (row) => {
                            await postResumeAlarm(row.id);
                        },
                    },
                    {
                        label: 'Close alarm',
                        icon: <Close />,
                        onClick: async (row) => {
                            await postCloseAlarm(row.id);
                        },
                    },
                ]}
            />
        </EdsPage>
    );
}

export default QueuePage;
