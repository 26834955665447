import { useTranslation } from 'react-i18next';
import {
    EdsContainer,
    EdsContainerGrid,
    EdsDataGrid,
    EdsTableDatetimeSeconds,
} from '../../../eds';

export const UmoAlarmDetailGrid = ({ entity }) => {
    const { t } = useTranslation();

    return (
        <EdsContainerGrid>
            <EdsContainer
                title={t('52e2d70076fff88ff4683779a64bf12a', 'Alarm detail')}
            >
                <EdsDataGrid
                    gridData={{
                        styles: {
                            left: {
                                key: {
                                    width: '400px',
                                },
                            },
                            right: {
                                key: {
                                    width: '400px',
                                },
                            },
                        },
                        rows: {
                            left: [
                                {
                                    key: t(
                                        '07cc694b9b3fc636710fa08b6922c42b',
                                        'Time'
                                    ),
                                    value: (
                                        <EdsTableDatetimeSeconds
                                            value={entity.timeStamp}
                                        />
                                    ),
                                },
                                {
                                    key: t(
                                        '57d50ee4873d42049cc021a5df38b750',
                                        'Device code'
                                    ),
                                    value: entity.deviceCode,
                                },
                                {
                                    key: t(
                                        '15c46c6e8cc3bc71a1f7ac25fd043a38',
                                        'Criteria'
                                    ),
                                    value: `${entity.criterion} - ${
                                        entity.alarmCriterion?.name
                                    } ${
                                        entity.alarmLocation?.description
                                            ? `(${entity.alarmLocation?.description})`
                                            : ''
                                    }`,
                                },
                                {
                                    key: t(
                                        '906eb66e47713fb446120ba0c8622552',
                                        'Alarm type'
                                    ),
                                    value: entity.alarmType?.name,
                                },
                                {
                                    key: t(
                                        '4fdd6cb2bc373668698bd563f5fdc9f3',
                                        'Response time'
                                    ),
                                    value: entity.acceptTime,
                                },
                                {
                                    key: t(
                                        'f0fc4a463c02c3acb338d3418c797b0e',
                                        'Operator remark'
                                    ),
                                    value: entity.operatorRemarks,
                                },
                                {
                                    key: t(
                                        'd504a5ea65b088497578bdd812714d51',
                                        'Memo'
                                    ),
                                    value: entity.memo,
                                },
                            ],
                            right: [
                                {},
                                {
                                    key: t(
                                        '0898b22730d57afcd394d8e4889ece4a',
                                        'CLI'
                                    ),
                                    value: entity.cli,
                                },
                                {
                                    key: t(
                                        '7c49b153d4b59f8c0cf8c3e18dc80cb7',
                                        'Origin'
                                    ),
                                    value: entity.alarmOrigin?.name,
                                },
                                {},
                                {
                                    key: t(
                                        '0647b09fa6d83f0d514ffaf1fe91e947',
                                        'Handle time'
                                    ),
                                    value: entity.handleTime,
                                },
                                {
                                    key: t(
                                        '7ca59acc35122d360dba25becda90891',
                                        'Alarm remark'
                                    ),
                                    value: entity.alarmRemark,
                                },
                            ],
                        },
                    }}
                />
            </EdsContainer>
        </EdsContainerGrid>
    );
};
