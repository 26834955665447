import { useRouteLoaderData } from 'react-router-dom';
import { EdsContainerGrid, EdsTabs } from '../../../components';
import _ from 'lodash';

function ProceduresTab() {
    const procedures = useRouteLoaderData('alarm-detail/procedures');

    if (_.isNil(procedures) || _.isEmpty(procedures)) {
        return <EdsContainerGrid />;
    }

    const tabs = procedures.map((item, index) => {
        return {
            label: item.description,
            transparent: true,
            index: index === 0,
            slug: `${item.id}`,
        };
    });

    return (
        <>
            <EdsTabs contained={true} tabs={tabs} router={true} />
        </>
    );
}

export default ProceduresTab;
