import { useTranslation } from 'react-i18next';
import { EdsContainerTable, EdsTableDatetimeSeconds } from '../../../eds';

export const UmoAlarmReasonsTable = ({ getDataCallback, ...props }) => {
    const { t } = useTranslation();

    return (
        <EdsContainerTable
            title={
                props.title ??
                t('a7539bfca5391465163bec6c759ac873', 'Alarm reason')
            }
            headers={[
                {
                    key: 'timestamp',
                    header: t('07cc694b9b3fc636710fa08b6922c42b', 'Time'),
                },
                {
                    key: 'reason',
                    header: t('40bea8d637cdf2c1b07fcf0630482b73', 'Reason'),
                },
                {
                    key: 'operator',
                    header: t('4b583376b2767b923c3e1da60d10de59', 'Operator'),
                },
            ]}
            getDataCallback={getDataCallback}
            mappingCallback={(responseData) => {
                return {
                    id: responseData.id,
                    timestamp: (
                        <EdsTableDatetimeSeconds
                            value={responseData.timestamp}
                        />
                    ),
                    reason: responseData.reason?.name,
                    operator: responseData.operatorName,
                };
            }}
        ></EdsContainerTable>
    );
};
